import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace ABIUrbanRatRace {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: true,
      inputs: [
        {
          type: "dropdown",
          flex: 2,
          label: Constants.dog_class_element.tubes,
          options: [
            { value: "None", id: "None" },
            {
              value: Constants.dog_class_element_level.rookie,
              id: Constants.dog_class_element_level.rookie,
            },
            {
              value: Constants.dog_class_element_level.clever,
              id: Constants.dog_class_element_level.clever,
            },
            {
              value: Constants.dog_class_element_level.adventurer,
              id: Constants.dog_class_element_level.adventurer,
            },
            {
              value: Constants.dog_class_element_level.explorer,
              id: Constants.dog_class_element_level.explorer,
            },
            {
              value: Constants.dog_class_element_level.detective,
              id: Constants.dog_class_element_level.detective,
            },
          ],
        },
        {
          type: "dropdown",
          defaultValue: "None",
          label: "Section",
          options: [
            { value: "None", id: "None" },
            {
              value: Constants.dog_class_section.on_leash,
              id: Constants.dog_class_section.on_leash,
            },
            {
              value: Constants.dog_class_section.unleashed,
              id: Constants.dog_class_section.unleashed,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: true,
      inputs: [
        {
          type: "dropdown",
          flex: 2,
          label: Constants.dog_class_element.unobstructed,
          options: [
            { value: "None", id: "None" },
            {
              value: Constants.dog_class_element_level.rookie,
              id: Constants.dog_class_element_level.rookie,
            },
            {
              value: Constants.dog_class_element_level.clever,
              id: Constants.dog_class_element_level.clever,
            },
            {
              value: Constants.dog_class_element_level.adventurer,
              id: Constants.dog_class_element_level.adventurer,
            },
            {
              value: Constants.dog_class_element_level.explorer,
              id: Constants.dog_class_element_level.explorer,
            },
            {
              value: Constants.dog_class_element_level.detective,
              id: Constants.dog_class_element_level.detective,
            },
          ],
        },
        {
          type: "dropdown",
          defaultValue: "None",
          label: "Section",
          options: [
            { value: "None", id: "None" },
            {
              value: Constants.dog_class_section.on_leash,
              id: Constants.dog_class_section.on_leash,
            },
            {
              value: Constants.dog_class_section.unleashed,
              id: Constants.dog_class_section.unleashed,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: true,
      inputs: [
        {
          type: "dropdown",
          flex: 2,
          label: Constants.dog_class_element.obstructed,
          options: [
            { value: "None", id: "None" },
            {
              value: Constants.dog_class_element_level.rookie,
              id: Constants.dog_class_element_level.rookie,
            },
            {
              value: Constants.dog_class_element_level.clever,
              id: Constants.dog_class_element_level.clever,
            },
            {
              value: Constants.dog_class_element_level.adventurer,
              id: Constants.dog_class_element_level.adventurer,
            },
            {
              value: Constants.dog_class_element_level.explorer,
              id: Constants.dog_class_element_level.explorer,
            },
            {
              value: Constants.dog_class_element_level.detective,
              id: Constants.dog_class_element_level.detective,
            },
          ],
        },
        {
          type: "dropdown",
          defaultValue: "None",
          label: "Section",
          options: [
            { value: "None", id: "None" },
            {
              value: Constants.dog_class_section.on_leash,
              id: Constants.dog_class_section.on_leash,
            },
            {
              value: Constants.dog_class_section.unleashed,
              id: Constants.dog_class_section.unleashed,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.fantastic_five,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.fantastic_five,
              id: Constants.dog_class_element_level.fantastic_five,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.tempting_ten,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.tempting_ten,
              id: Constants.dog_class_element_level.tempting_ten,
            },
          ],
        },
      ],
    },
  ];
}
