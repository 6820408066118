import React from "react";
import { Helmet } from "react-helmet";

import "./TermsOfService.scss";

interface IProps {}

const TermsOfService: React.FC<IProps> = (props) => {
  return (
    <div className="TermsOfService">
    <Helmet>
      <title>Terms of Service</title>
        <meta name="description" content="By accessing or using the Eager Dog platform, you agree to be bound by these Terms of Service." />
        <meta name="robots" content="index, follow" />
    </Helmet>
    <div className="genericInner">
      <p>Effective Date: August 25th, 2024</p>

      <h1>Acceptance of Terms</h1>

      <p>By accessing or using the Eager Dog platform, you agree to be bound by these Terms of Service (“Terms”). If you do not agree to these Terms, you may not use the platform.</p>

      <h2>Description of Services</h2>

      <p>Eager Dog is a specialized software platform designed to assist Kennel Clubs and event secretaries in managing and organizing their events more efficiently. Unlike traditional secretary services, Eager Dog does not currently offer ribbon services or on-site event management services. Instead, we focus on providing a robust, user-friendly software solution that enhances the effectiveness of both secretaries and clubs.</p>

      <p>Our platform offers a comprehensive suite of tools to streamline event management, including the following key features:</p>

      <ul>
        <li><b>Dog Profiles:</b> Users can save their dog’s profiles within their Eager Dog account, making future registrations faster and easier. This feature simplifies the registration process by allowing users to reuse their saved information for subsequent events without re-entering the same details.</li>
        <li><b>Complete Registration Management:</b> Eager Dog facilitates both online and offline registrations, serving as a complete management system for clubs and secretaries. The platform allows for the management of all registrations as they come in, regardless of the payment method used. Registrants can still pay by cash, cheque, e-transfer, etc., and the club can manually enter these payments into the system to ensure all paperwork is up-to-date. </li>
        <li><b>Automation of Event Documentation:</b> Eager Dog automates the creation and management of essential event documents, including Judges Books, Registration Forms, Scoresheets, and, in some cases, results and custom exports. This automation reduces the administrative burden on clubs and secretaries, allowing them to focus more on delivering a successful event.</li>
        <li><b>Paperwork and Compliance Responsibility:</b> While Eager Dog provides tools to help generate and manage event paperwork, it is the responsibility of the Kennel Club or event secretary to ensure that all paperwork submitted to sanctioning organizations such as the AKC, UKC, or other governing bodies is accurate and complies with their regulations. Eager Dog is not responsible for any incorrect or incomplete paperwork or errors in registration information that may result in the loss of licensing or other penalties from sanctioning clubs.</li>
      </ul>

      <p>Eager Dog is designed to be a powerful tool that enables Kennel Clubs and event secretaries to run their events smoothly and efficiently, without the need for traditional secretary services on-site.</p>

      <h2>User Accounts</h2>

      <p>To access certain features of the platform, you must create an account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account. You agree to notify Eager Dog immediately of any unauthorized use of your account.</p>

      <h2>Fees and Payment</h2>

      <p>Eager Dog uses Stripe as our payment processor to handle all transactions related to the use of our platform. When you register for an event through Eager Dog, a 10% (+ HST) fee is applied to the total registration cost. This fee includes both the payment processing fees charged by Stripe and the fees for using the Eager Dog platform.</p>

      <ul>
        <li><b>Payment Processing:</b> Payments are securely processed through Stripe. By registering for an event, you agree to Stripe’s terms and conditions, which govern the payment processing services.</li>
        <li><b>Fee Structure:</b> The 10% (+ HST) fee covers all costs associated with processing the payment and providing the Eager Dog platform services. This fee is automatically calculated and added to your total at the time of payment. Additionally, a 5% fee is charged to the club for each offline registration manually entered into the system by the club.</li>
        <li><b>Refund Policy:</b> The Stripe payment processing fees and Eager Dog platform fees are non-refundable under any circumstances. However, the registration fee that you pay for an event may be refunded by the hosting club at their discretion. If you seek a refund of the registration fee, you must contact the club directly, as they determine and process any refunds according to their own policies.</li>
      </ul>

      <h2>Use of the Platform</h2>

      <p>You agree to use the Eager Dog platform in compliance with all applicable laws and regulations. You are solely responsible for any content you post or submit through the platform and must ensure that such content does not infringe on the rights of others.</p>

      <h2>Intellectual Property</h2>

      <p>All content and materials available on the Eager Dog platform, including but not limited to text, graphics, logos, and software, are the property of Eager Dog or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from the platform without prior written permission from Eager Dog.</p>

      <h2>Limitation of Liability</h2>

      <p>Eager Dog is not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the platform. This includes, but is not limited to, any errors or omissions in content, loss of data, or any interruption of service.</p>

      <h2>Indemnification</h2>

      <p>You agree to indemnify, defend, and hold harmless Eager Dog and its affiliates, officers, directors, employees, and agents from and against any and all claims, damages, losses, liabilities, and expenses arising out of your use of the platform, your violation of these Terms, or your violation of any rights of another.</p>

      <h2>Termination</h2>

      <p>Eager Dog reserves the right to suspend or terminate your account and access to the platform at any time, without notice, for conduct that violates these Terms or is otherwise harmful to other users, Eager Dog, or third parties.</p>

      <h2>Governing Law</h2>

      <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Eager Dog is headquartered, without regard to its conflict of law principles.</p>

      <h2>Changes to Terms</h2>

      <p>Eager Dog reserves the right to modify or update these Terms at any time. Any changes will be effective immediately upon posting on the platform. Your continued use of the platform after any such changes constitutes your acceptance of the new Terms.</p>

      <h2>Contact Information</h2>

      <p>If you have any questions about these Terms, please contact us at:</p>

      <p><b>Eager Dog</b><br />
      2967 Dundas St. W. #1664<br />
      Toronto, ON M6P 1Z2<br />
      info@eagerdog.com</p>
    </div>
    </div>
  );
};

export default TermsOfService;
