import React, { useEffect, useState } from 'react';

import './HorizontalScroll.scss';

interface IProps {
  children: JSX.Element[] | JSX.Element | any[],
  className?: string
}

const HorizontalScroll: React.FC<IProps> = (props) => {
  const listRef = React.createRef<HTMLUListElement>();
  const wrapRef = React.createRef<HTMLDivElement>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [transform, setTransform] = useState<number>(0);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const scrollShowTypes = (e: any) => {
    if (listRef.current && wrapRef.current) {
      const max = -(listRef.current.offsetWidth - wrapRef.current.offsetWidth);
      setTransform(Math.min(Math.max((transform - e.deltaY), max), 0));
    }
  }

  const goToStart = (e: any) => {
    setTransform(0);
  }

  const goToEnd = (e: any) => {
    if (listRef.current && wrapRef.current) {
      const max = -(listRef.current.offsetWidth - wrapRef.current.offsetWidth);
      setTransform(max);
    }
  }

  useEffect(() => {
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    const preventDefault = (e: any) => {
      e.preventDefault();
    }

    const preventScrolling = () => {
      if (listRef.current && wrapRef.current) {
        if (listRef.current.offsetWidth > wrapRef.current.offsetWidth) {
          document.body.addEventListener('DOMMouseScroll', preventDefault, true);
          document.body.addEventListener(wheelEvent, preventDefault, { passive: false });
        }
      }
    }

    const continueScrolling = () => {
      document.body.removeEventListener('DOMMouseScroll', preventDefault, true);
      document.body.removeEventListener(wheelEvent, preventDefault);
    }

    if (wrapRef.current) {
      if (isHovered) {
        preventScrolling();
      }

      wrapRef.current.onmouseenter = () => {
        setIsHovered(true);
        preventScrolling();
        return false;
      }

      wrapRef.current.onmouseleave = () => {
        setIsHovered(false);
        continueScrolling();
        return false;
      }
    }

    return () => {
      continueScrolling();
    };
  }, [wrapRef, listRef, isHovered]);

  useEffect(() => {
    if (listRef.current && wrapRef.current) {
      if (listRef.current.offsetWidth > wrapRef.current.offsetWidth) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      listRef.current.style.transform = "translate("+ transform +"px)";
    }
  }, [transform, listRef, wrapRef, dimensions]);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);

    return(() => {
      window.removeEventListener("resize", handleResize);
    })
  }, []);

  return(
    <div className={props.className ? "HorizontalScroll " + props.className : "HorizontalScroll"}>
      <div ref={wrapRef} className="horizontalWrap">
        {hasMore && <div onClick={(e) => { goToStart(e); }} className={transform === 0 ? "less hide": "less"}><div className="icon"></div></div>}
        <ul ref={listRef} onWheel={(e) => { scrollShowTypes(e); }} className="horizontalList">
          {props.children}
        </ul>
        {hasMore && <div onClick={(e) => { goToEnd(e); }} className={transform !== 0 ? "more hide": "more"}><div className="icon"></div></div>}
      </div>
    </div>
  );
}

export default HorizontalScroll;