import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace AKCFetch {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.fetch,
          multiSelect: false,
          options: [
            {
              value: Constants.dog_class_element_level.novice,
              id: Constants.dog_class_element_level.novice,
            },
            {
              value: Constants.dog_class_element_level.intermediate,
              id: Constants.dog_class_element_level.intermediate,
            },
            {
              value: Constants.dog_class_element_level.advanced,
              id: Constants.dog_class_element_level.advanced,
            },
            {
              value: Constants.dog_class_element_level.retriever,
              id: Constants.dog_class_element_level.retriever,
            },
          ],
        },
      ],
    },
  ];
}
