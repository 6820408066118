import React, { useState, useEffect } from 'react';

import Button from "src/components/Button/Button";
import { helperService } from "src/services/helper.service";

import './StepForm.scss';

interface IStepProps {
  children: string | JSX.Element | JSX.Element[],
  onSubmit?(): boolean,
  onStep?(): void
}

export const useStepForm = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const switchToTab = (index:number) => {
    setActiveTab(index);
  }

  return { switchToTab, activeTab };
}

export const Step: React.FC<IStepProps> = (props) => {
  return (
    <div className="Step">
      {props.children}
    </div>
  );
}

interface IProps {
  steps: string[],
  children: React.ReactElement<IStepProps>[],
  onSubmit?: any,
  next(index: number): void,
  activeTab: number,
  finishText?: string
  disabled?: boolean
  isLoading?: boolean
}

const StepForm: React.FC<IProps> = (props) => {
  const prevActiveTab = helperService.usePrevious(props.activeTab);

  useEffect(() => {
    if (prevActiveTab !== props.activeTab) {
      props?.children[props.activeTab]?.props?.onStep?.();
    }
  }, [props.children, props.activeTab, prevActiveTab]);

  const submit = (e: any) => {
    if (props.activeTab !== props.steps.length - 1) {
      props.next(props.activeTab + 1 < props.steps.length - 1 ? props.activeTab + 1 : props.steps.length - 1);
    } else {
      props.onSubmit();
    }
  }

  let offset:number = 0;

  return (
    <div className="StepForm">
      <ul className="sidebar">
        {props.steps.map((step: string, stepIndex: number) => {
          return(<li className={stepIndex === props.activeTab ? "step active" : stepIndex > props.activeTab ? "step next": "step previous"} key={stepIndex} onClick={() => { props.next(stepIndex); }}>{step}</li>);
        })}
      </ul>
        <div className="stepWrap">
          <div className="stepInner">
            {props.children.map((c:React.ReactElement<IStepProps>, childIndex:number) => {
              if (!c.props.children) {
                offset++;
                return(false);
              } else {
                return(
                  <form key={childIndex - offset} onSubmit={(e) => {
                    e.preventDefault();

                    if (c.props.onSubmit) {
                      if (c.props.onSubmit()) {
                        submit(e);
                      }
                    } else {
                      submit(e);
                    }
                  }} className={(childIndex - offset) === props.activeTab ? "current": ""}>
                    {c}
                    <div className="actions">
                      <div onClick={() => { props.next(props.activeTab - 1 > 0 ? props.activeTab - 1 : 0); }} className={props.activeTab - 1 >= 0 ? "action" : "action disabled"}><div className="caret left"></div><span>Previous</span></div>
                      {/* New buttons */}
                      {/* {props.activeTab !== props.steps.length - 1 && <Button className="action" type="submit" variant='secondary'>Next <div className="caret right"></div></Button> */}
                      {props.activeTab === props.steps.length - 1 && <Button type="submit" disabled={props?.disabled} isLoading={props?.isLoading}><span>{props.finishText ? props.finishText : "Finish"}</span></Button>}
                      {/* Old buttons */}
                      {props.activeTab !== props.steps.length - 1 && <button type="submit" className="action"><span>Next</span>  <div className="caret right"></div></button>}
                      {/* {props.activeTab === props.steps.length - 1 && <button type="submit" className="action finish"><span>{props.finishText ? props.finishText : "Finish"}</span></button>} */}
                    </div>
                  </form>
                );
              }
            })}
          </div>
        </div>
    </div>
  );
}

export default StepForm;