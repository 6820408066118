import React, { useState, useRef, ChangeEvent } from "react";

import "./SearchDown.scss";

interface IProps {
  defaultValue?: string,
  label?: string,
  placeholder?: string,
  icon?: string,
  type?: string,
  required?: boolean,
  onChange?(e: ChangeEvent<HTMLInputElement>): void,
  id?: string,
  onSearch(value:string): void,
  searchResults: string[],
  onSelect(value: string): void,
  limit?: number
}

const SearchDown: React.FC<IProps> = (props) => {
  const [inputClass, setInputClass] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const inputPreClass = props.icon ? "searchInput hasIcon" : "searchInput noIcon";

  const setFocus = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      setInputClass("focused");
    }
  }

  const onBlur = () => {
    setInputClass("");
  }

  const hasValue = () => {
    if (inputRef && inputRef.current) {
      if (inputRef.current.value.length > 0) {
        return true;
      }
    }

    return false;
  }

  const setValue = (value: string) => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = value;
    }

    props.onSelect(value);
  }

  return (
    <div className="SearchDown">
      <div onClick={() => { setFocus(); }} className={"focused " + props.placeholder ? inputPreClass +" "+ inputClass : hasValue() ? inputPreClass +" "+ inputClass + " noPlaceholder focused" : inputPreClass +" "+ inputClass + " noPlaceholder"}>
        {props.label ? <label><span>{props.label}</span></label> : null}
        {props.icon && <img className="icon" alt="input icon" src={props.icon} />}
        <input
          defaultValue={props.defaultValue ? props.defaultValue : ""}
          id={props.id ? props.id : ""}
          onChange={(e) => { if (props.onChange) { props.onChange(e); } props.onSearch(e.target.value); }}
          required={props.required ? true : false}
          onFocus={() => { setFocus(); }}
          type={props.type ? props.type : "text"}
          onBlur={() => { onBlur(); }}
          ref={inputRef}
          placeholder={props.placeholder ? props.placeholder : ""} 
          autoComplete="off"
        />
      </div>
      {props.searchResults.length > 0 && <div className="searchResults">
        <ul>
          {props.searchResults.map((item: string, index: number) => {
            return(<li key={index} onClick={() => { setValue(item); }}>{item}</li>);
          })}
        </ul>
      </div>}
    </div>
  );
};

export default SearchDown;