export namespace localStorageService {
  //
  export const ACCESS_TOKEN = "access_token";
  export const USER = "user";
  export const DOWNLOADS = "downloads";

  export const setItem = (item: string, value: string) => {
    localStorage.setItem(item, value);
  };

  export const getItem = (item: string): string | null => {
    return localStorage.getItem(item);
  };

  export const removeItem = (key: string) => {
    localStorage.removeItem(key);
  };
}
