import React, { useEffect } from 'react';

import './DiscountForm.scss';
import Input from '../Input/Input';
import { Constants } from '@eagerdog/constants';
import Dropdown, { IOption } from '../Dropdown/Dropdown';
import { apiService } from 'src/services';
import { toast } from '../Toast/ToastManager';
import { AxiosError } from 'axios';
import Button from '../Button/Button';

interface DiscountFormProps {  
  onFinish: () => void,
  eventId: string
}

const DiscountForm: React.FC<DiscountFormProps> = (props: DiscountFormProps) => {
	const [code, setCode] = React.useState<string>("");
	const [amount, setAmount] = React.useState<number>(0);
	const discountTypes:IOption[] = [ { value: Constants.discount_type.percentage, id: Constants.discount_type.percentage }, { value: "amount", id: Constants.discount_type.amount } ];
	const [ discountType, setDiscountType ] = React.useState<IOption>(discountTypes[0]);
	// const [ eventOptions, setEventOptions ] = React.useState<IOption[]>([{ value: '', id: '' }]);

	useEffect(() => {
		fetchEvents();
	}, []);
	
	const handleDiscount = () => {
		let payload = {
			discount_code: code,
			discount_value: amount,
			discount_type: discountType.value,
		}

		apiService.createEventDiscount(props.eventId, payload).then((res) => {
			toast.show({
				title: "Discount Created",
				content: `Successfully created discount code ${code}.`,
				duration: 10000,
				type: "success"
			});

			props.onFinish();
		});
	}

	const fetchEvents = () => {
		apiService.getCurrentUserClubs().then((response) => {
				const clubId = response._id;

				apiService.getClubEvents(clubId).then((response) => {
					/*let _eventOptions:IOption[] = response.map((event) => {
							return {
									value: event.title,
									id: event?._id
							}
					});*/

					//setEventOptions(_eventOptions);
				}).catch((e: AxiosError) => {
					toast.show({
						title: "Get Events",
						content: "Unable to get events",
						duration: 10000,
						errorDetails: e,
						type: "fail"
					});
				});
				}).catch((e: AxiosError) => {
				toast.show({
					title: "Get Events",
					content: "Unable to get events",
					duration: 10000,
					errorDetails: e,
					type: "fail"
				});         
		});
	}
	
	return (
		<form className='DiscountForm' onSubmit={(e) => { e.preventDefault(); handleDiscount(); }}>
			<div className="title">Discounts</div>
			<div className="formWrap">
				<Input label="Discount Code" required={true} type="text" onChange={(e) => { e.stopPropagation(); let _code = e.target.value.toUpperCase().replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/g, ""); setCode(_code); }} placeholder="Set Your Discount Code" defaultValue={code} />
				<Input label="Discount Amount" type="number" step="0.01" onChange={(e) => { e.stopPropagation(); setAmount(parseFloat(e.target.value)) }} placeholder="Set Your Discount Amount" />
				<Dropdown value={discountType} onChange={(e: any, value: IOption) => { setDiscountType(value); }} label="Discount Type" options={discountTypes} placeholder="Choose between fixed or percent discounts." />
			</div>
			<div className="actions">
				<Button type="submit">Create Discount</Button>
			</div>
		</form>
	)
}

export default DiscountForm;
