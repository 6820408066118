import React from 'react';
import { /*IAddress, */IDogCoownerInfo } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Button from "src/components/Button/Button";
// import UserAddress from "src/routes/EventRegister/UserAddress/UserAddress";

import "./OwnerList.scss";

interface IProps {
  owners: IDogCoownerInfo[],
  onChange(owners: IDogCoownerInfo[]): void,
}

const OwnerList: React.FC<IProps> = (props) => {
  const initialOwner: IDogCoownerInfo = {
    coowner_type: "owner",
    coowner_number: "",
    email: "",
    first_name: "",
    last_name: "",
    display_name: "",
    phone: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postal_code: ""
    }
  };

  const setOwner = (owner: any, index: number) => {
    let _owners = JSON.parse(JSON.stringify(props.owners));

    _owners[index] = { ..._owners[index], ...owner };

    props.onChange(_owners);
  }

  const addCoOwner = () => {
    let _owners = JSON.parse(JSON.stringify(props.owners));

    _owners.push({
      ...initialOwner
    });

    props.onChange(_owners);
  }

  const deleteCoOwner = (index: number) => {
    let _owners = JSON.parse(JSON.stringify(props.owners));

    _owners.splice(index, 1);

    props.onChange(_owners);
  }

  return (
    <div className="OwnerList">
      {props.owners.map((c: IDogCoownerInfo, index: number) => {
        return <div key={index} className={"coOwnerWrap coOwner" + index}>
          <div className="coOwnerTitle">Co-Owner {index + 1}</div>
          <div className="coOwnerDelete"><div onClick={() => { deleteCoOwner(index); }}className="close"></div></div>
          <div key={index} className="row">
            <Input label="First Name" id={"ownerFirst"+index} type="string" onChange={(e) => { e.stopPropagation(); setOwner({ first_name: e.target.value }, index); }} placeholder="What's the owners first name?" defaultValue={c.first_name} />
            <Input label="Last Name" id={"ownerLast"+index} type="string" onChange={(e) => { e.stopPropagation(); setOwner({ last_name: e.target.value }, index); }} placeholder="What's the owners last name?" defaultValue={c.last_name} />
          </div>
          {/*<Input label="Email" id={"ownerEmail"+index} type="email" onChange={(e) => { e.stopPropagation(); setOwner({ email: e.target.value }, index); }} defaultValue={c.email} placeholder="What's the owners email?" />
          <UserAddress user={c} required={false} onChange={(newAddress: IAddress, phone: string) => { setOwner({ address: newAddress, phone: phone }, index); }} />*/}
        </div>;
      })}
      <div className="addCoOwner">
        <Button onClick={(e) => { e.preventDefault(); addCoOwner(); }}>Add Another Co-Owner</Button>
      </div>
    </div>
  );
}

export default OwnerList;