import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

import './Congrats.scss';

interface IProps {
  title?: string,
  text: string,
  urlText: string,
  url?: string,
  action?(): void,
  optionalAction?(): void,
  optionalActionText?: string
}

const Congrats: React.FC<IProps> = (props) => {
  return(
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.1, delay: 0.2 }} className="Congrats">
      <motion.div initial={{ opacity: 0, top:-220 }} whileInView={{ opacity: 1, top:-210 }} transition={{ duration: 0.1, delay: 0.6 }} className="mainBubble">
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.1, delay: 0.4 }} className="bubbleInner">
          <motion.div whileInView={{ right: -60, bottom: 30 }} transition={{ type: "spring", stiffness: 500, duration: 0.1, delay: 0.6 }} className="bubble rightBig"></motion.div>
          <motion.div whileInView={{ right: -70, bottom: -30 }} transition={{ type: "spring", stiffness: 500, duration: 0.1, delay: 0.6 }} className="bubble rightSmall"></motion.div>
          <motion.div whileInView={{ left: 20, bottom: 90 }} transition={{ type: "spring", stiffness: 500, duration: 0.1, delay: 0.6 }} className="bubble leftBig"></motion.div>
          <motion.div whileInView={{ left: -30, bottom: -10 }} transition={{ type: "spring", stiffness: 500, duration: 0.1, delay: 0.6 }} className="bubble leftSmall"></motion.div>
        </motion.div>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.1, delay: 0.9 }} className="content">
        <div className="title">{props.title ? props.title : "Congratulations!"}</div>
        <p>{props.text}</p>
        <div className="actions">
          {props.optionalActionText && <button onClick={(e) => { e.preventDefault(); if (props.optionalAction) { props.optionalAction(); } }} className="optionalAction">{props.optionalActionText}</button>}
          {props.action && <button onClick={() => { if (props.action) { props.action(); } }}>Close</button>}
          {props.url && <Link className="mainLink" to={props.url}>{props.urlText}</Link>}
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Congrats;