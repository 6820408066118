export const EagerdogErrors = {
  SYSTEM: {
    UNEXPECTED: "ERROR.SYSTEM.UNEXPECTED",
  },

  AUTH: {
    UNAUTHORIZED: "ERROR.AUTH.UNAUTHORIZED",
    ACCESS_TOKEN_EXPIRED: "ERROR.AUTH.ACCESS_TOKEN_EXPIRED",
    REFRESH_TOKEN_EXPIRED: "ERROR.AUTH.REFRESH_TOKEN_EXPIRED",
    EMAIL_TOKEN_INVALID: "ERROR.AUTH.EMAIL_TOKEN_INVALID",
    NO_OBJECT_PERMISSIONS: "ERROR.AUTH.NO_OBJECT_PERMISSIONS",
    INVALID_USER_UPDATE: "ERROR.AUTH.INVALID_USER_UPDATE",
  },

  DATA: {
    RECORD_ALREADY_EXISTS: "ERROR.DATA.RECORD_ALREADY_EXISTS",
    MAX_RECORDS_REACHED: "ERROR.DATA.MAX_RECORDS_REACHED",
  },

  STORAGE: {
    FILE_UPLOAD_ERROR: "ERROR.STORAGE.FILE_UPLOAD_ERROR",
  },

  FORMS: {
    NO_CONTENT: "ERROR.FORMS.NO_CONTENT",
    NOT_READY_FOR_DOWNLOAD: "ERROR.FORMS.NOT_READY_FOR_DOWNLOAD",
  },

  // PAYMENT: {
  //   INVALID_PAYMENT_METHOD: "ERROR.PAYMENT.INVALID_PAYMENT_METHOD",
  // },
  REGISTRATION: {
    INVALID_REGISTRATION_KIND: "ERROR.REGISTRATION.INVALID_REGISTRATION_KIND",
    EXCLUDED_BREED: "ERROR.REGISTRATION.EXCLUDED_BREED",
  },

  SCORES: {
    INVALID_SHOW_TYPE: "ERROR.SCORES.INVALID_SHOW_TYPE",
    SCORES_NOT_ENTERED: "ERROR.SCORES.SCORES_NOT_ENTERED",
    INVALID_CLASSES_COMBINATION: "ERROR.SCORES.INVALID_CLASSES_COMBINATION",
    SCORES_ALREADY_ENTERED: "ERROR.SCORES.SCORES_ALREADY_ENTERED",
  },

  RUN_TIMES: {
    RUN_TIME_NOT_AVAILABLE: "ERROR.RUN_TIMES.RUN_TIME_NOT_AVAILABLE",
  },
};
