import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace ConformationJunior {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
      type: "dropdown", label: "Junior Showmanship", multiSelect: true, options: [
        { value: Constants.dog_class_element_level.pee_wee, id: Constants.dog_class_element_level.pee_wee },
        { value: Constants.dog_class_element_level.sub_junior, id: Constants.dog_class_element_level.sub_junior },
        { value: Constants.dog_class_element_level.pre_junior, id: Constants.dog_class_element_level.pre_junior },
        { value: Constants.dog_class_element_level.novice_junior, id: Constants.dog_class_element_level.novice_junior },
        { value: Constants.dog_class_element_level.open_junior, id: Constants.dog_class_element_level.open_junior },
        { value: Constants.dog_class_element_level.novice_senior, id: Constants.dog_class_element_level.novice_senior },
        { value: Constants.dog_class_element_level.open_senior, id: Constants.dog_class_element_level.open_senior }
      ]
    }]
  }, {
    allowDuplicates: false,
    inputs: [{
      type: "checkbox", label: "Non-Licensed Classes", multiSelect: true, options: [
        { value: Constants.dog_class_element_level.total_junior, id: Constants.dog_class_element_level.total_junior }
      ]
    }]
  }];
}