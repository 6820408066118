import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { helperService, IDogPortrait } from "../../services/helper.service";
import { PasswordResetDto } from "@eagerdog/interfaces";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

import "../../styles/Signup.scss";

import logo from "../../assets/logo.svg";
import keyIcon from "../../assets/icons/key.svg";
import eyeIcon from "../../assets/icons/eye.svg";
import eyeOffIcon from "../../assets/icons/eye_off.svg";

import Input from "../../components/Input/Input";
import { toast } from "../../components/Toast/ToastManager";
import { apiService } from "../../services";

interface IProps {}

const ChooseNewPassword: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [showPassword, setShowPassword] = useState (false)
  const [showConfirmPassword, setShowConfirmPassword] = useState (false)
  
  useEffect(() => {
    if (helperService.isLoggedIn()) {
      navigate("/");
    }
  });

  const resetPassword = () => {   

    const token = new URLSearchParams(location.search).get('token')?? ''
    if (!token ) {
        return;
    }

    if (password !== confirmPass) {
      toast.show({
        title: "Reset Password",
        content: "The passwords do not match",
        duration: 10000,
        type: "fail"
      });
      return;
    }

    let payload:PasswordResetDto = {
      token: token,
      new_password: password
    };

    apiService.passwordReset(payload).then((response: any) => {      
      toast.show({
        title: "Reset Password",
        content: "Your password was changed successfully",
        duration: 10000,
        type: "success"
      });
      navigate("/login");      
    }).catch((error:any) => {
      toast.show({
        title: "Reset Password",
        content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
        duration: 10000,
        type: "fail"
      });
    });
  }

  const changeShowPassword = () => {
    setShowPassword (!showPassword)
  }

  const changeShowConfirmPassword = () => {
    setShowConfirmPassword (!showConfirmPassword)
  }

  return (
    <div className="Login Signup">
    <Helmet>
      <title>Choose a New Password</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
      <div className="images">
        <div className="logo"><Link to="/"><img src={logo} alt="Eager Dog" /></Link></div>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }} className="dogCards">
          <div className="cardRow">
            {helperService.topDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
          <div className="cardRow">
            {helperService.bottomDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
        </motion.div>
        <motion.div initial={{ opacity: 0, translateY: "-300px" }} whileInView={{ opacity: 1, translateY: "-50px" }} transition={{ delay: 0.5 }} className="dogBanner">
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 1 }} className="bannerInner">
            <div className="small">The Best Place</div>
            <div className="large">for your <span>Dog</span></div>
            <div className="dogWrap">
              <div className="portrait"><img src={helperService.allDogs[2].image} alt={"Dog Portrait of " + helperService.allDogs[2].name} /></div>
            </div>
            <div className="dogName">Stan</div>
          </motion.div>
        </motion.div>
      </div>
      <div className="content">
        <div className="inner">
          <div className="logo"><img src={logo} alt="Eager Dog" /></div>
          <div className="reset-title">
            <h1 >Reset Password</h1>
            <div>Almost done! <br /> Enter your new password and you're all set.</div>
          </div>                    
          <div className="form">
            <form onSubmit={(e) => { e.preventDefault(); resetPassword(); }}>
              <Input type={showPassword? "text": "password"} onClickEye={changeShowPassword} onChange={(e:any) => { setPassword(e.target.value); }} icon={keyIcon} eye={showPassword? eyeIcon: eyeOffIcon} label="Password" placeholder="Enter your password" />
              <div className="validation">Password must contain at least 8 characters</div>
              <Input type={showConfirmPassword? "text": "password"} onClickEye={changeShowConfirmPassword} onChange={(e:any) => { setConfirmPass(e.target.value); }} icon={keyIcon} eye={showConfirmPassword? eyeIcon: eyeOffIcon} label="Confirm Password" placeholder="Enter your password" />    
              <div className="actions">
                <button type="submit">Reset Password</button>
              </div>              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseNewPassword;
