import React, { useState } from 'react';

import Input from "src/components/Input/Input";
import Button from "src/components/Button/Button";

import "./RibbonSkip.scss";

interface IProps {
	onSubmit(skipAmt: number): void
}

const RibbonSkip: React.FC<IProps> = (props) => {
	const [skipAmt, setSkipAmt] = useState<string>("0");

	return(
	<form className="RibbonSkip" onSubmit={(e: any) => { e.preventDefault(); props.onSubmit(parseInt(skipAmt)); }}>
		<div className="title">Download Ribbon Labels</div>
		<div className="content">
			<div className="text">How many labels would you like to skip?</div>
			<Input type="number" min="0" max="13" label="Skip Amount" onChange={(e: any) => { setSkipAmt(e.target.value); }} defaultValue={skipAmt} />
		</div>
		<div className="actions">
			<Button type="submit">Download</Button>
		</div>
	</form>
	);
}

export default RibbonSkip;