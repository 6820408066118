import React, { useEffect } from "react";
import { useNavigate, useLocation  } from "react-router-dom";
import { Helmet } from "react-helmet";

import { helperService } from "../../services/helper.service";
import { toast } from "../../components/Toast/ToastManager";

interface IProps {}

const Logout: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.search === "?session_expired=1") {
      toast.show({
        title: "Session Expired",
        content: "Your session has expired, please login again.",
        duration: 10000,
        type: "fail"
      });

      helperService.logout();

      navigate("/login");
    } else {
      if (helperService.isLoggedIn()) {
        toast.show({
          title: "Logout",
          content: "You've successfully logged out.",
          duration: 10000,
          type: "success"
        });
      }

      helperService.logout();

      navigate("/");
    }
  });

  return (
    <div className="Logout">
      <Helmet>
        <title>Logout</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
    </div>
  );
};

export default Logout;
