import React, { useEffect, useState } from "react";
// import { Constants } from "@eagerdog/constants";
import { IScore } from "@eagerdog/interfaces";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";

import "./FetchResults.scss";

interface IProps {
  result: IScore,
  onChange(result: any): void
}

const FetchResults: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [passed, setPassed] = useState<IOption>({ value: "Select a Value", id: ""});

  const passedOptions:IOption[] = [
    { value: "Yes", id: "Yes" },
    { value: "No", id: "No" }
  ];

  useEffect(() => {
    if (!loaded) {
      if (props.result && props.result.fetch) {
        if (props.result.fetch.passed !== undefined) {
          if (props.result.fetch.passed === true) {
            setPassed({ value: "Yes", id: "Yes" });
          } else {
            setPassed({ value: "No", id: "No" });
          }
        }
      }

      setLoaded(true);
    }
  }, [loaded, props.result]);

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        result_type: props.result._id,
        passed: passed.id === "Yes" ? true : false
      });

      setHasChanged(false);
    }
  }, [hasChanged, passed, props]);

  return (
    <div className="FetchResults">
      <Dropdown value={passed} label={"Passed"} onChange={(e: any, value: IOption) => { setPassed(value); setHasChanged(true); }} options={passedOptions} placeholder="" />
    </div>
  );
};

export default FetchResults;