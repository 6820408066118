import React, { useState } from 'react';
import { AxiosError } from "axios";

import { IEventTransactionSummary } from "@eagerdog/interfaces";

import { apiService } from "../../services";

import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { toast } from "../../components/Toast/ToastManager";

import "./Refund.scss";

interface IProps {
  transaction: IEventTransactionSummary,
  onRefund(): void,
  onCancel(): void
}

const Refund: React.FC<IProps> = (props) => {
  const [isRefunding, setIsRefunding] = useState<boolean>(false);
  const [fullAmount, setFullAmount] = useState<boolean>(true);
  const [customAmount, setCustomAmount] = useState<string>("0.00");

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props.transaction.amounts.currency.toUpperCase() || "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const refund = () => {
    setIsRefunding(true);

    let amountRefunded:number = 0;

    if (fullAmount === true) {
      amountRefunded = (props.transaction.amounts.calculated_base_amount / 100) - (props.transaction.amounts.refunded_amount ? (props.transaction.amounts.refunded_amount / 100) : 0);
    } else if (fullAmount === false) {
      amountRefunded = parseFloat(customAmount);
    }

    if (props.transaction.amounts.refunded_amount !== undefined && 
      (props.transaction.amounts.calculated_base_amount / 100) - (props.transaction.amounts.refunded_amount / 100) < amountRefunded) {
      toast.show({
        title: "Refund Transaction",
        content: "Refund failed because you're attempting to refund more money than the total transaction ("+ formatter.format(amountRefunded) +")",
        duration: 10000,
        type: "fail"
      });

      setIsRefunding(false);
    } else {
      apiService.refundEventTransaction(props.transaction.event_id, props.transaction._id, { amount: amountRefunded }).then((refundResponse: any) => {
        toast.show({
          title: "Refund Transaction",
          content: "Transaction has been successfully refunded, the transaction record may take a few minutes to update",
          duration: 10000,
          type: "success"
        });

        setIsRefunding(false);
        props.onRefund();
      }).catch((e: AxiosError) => {
        toast.show({
          title: "Refund Transaction",
          content: "Unable to refund transaction",
          duration: 10000,
          errorDetails: e,
          type: "fail"
        });
      }).finally(() => {
        setIsRefunding(false);
      });
    }
  }

  return(
    <form className="Refund" onSubmit={(e) => { e.preventDefault(); if (!isRefunding) { refund(); } }}>
      <div className="title">
        <span>Refund</span>
        <span>{props.transaction.user.display_name}</span>
      </div>
      <div className="content">
        {props.transaction.amounts.refunded_amount !== undefined && props.transaction.amounts.refunded_amount > 0 && <div className="warningWrap">
          <div className="warningIcon">
            <div className="icon warning"></div>
          </div>
          <div className="warningContent">
            This transaction has been partially refunded. Current maximum refund amount is {formatter.format((props.transaction.amounts.calculated_base_amount / 100) - (props.transaction.amounts.refunded_amount / 100))}.
          </div>
        </div>}
        <div className="checkboxList">
          <div className="listLabel">Refund Full Amount?</div>
          <div className="listWrap">
            <Checkbox id="yesFull" onChange={() => { setFullAmount(true); }} value={fullAmount === true} label={"Yes"} />
            <Checkbox id="noFull" onChange={() => { setFullAmount(false); }} value={fullAmount === false} label={"No"} />
          </div>
        </div>
        {fullAmount === false && <div className="customAmount">
          <Input type="number" min="5" max={((props.transaction.amounts.calculated_base_amount - (props.transaction.amounts.refunded_amount || 0)) / 100)} step=".01" label={"Custom Amount ("+ props.transaction.amounts.currency.toUpperCase() +")"} onChange={(e: any) => { setCustomAmount(e.target.value); }} defaultValue={customAmount} />
        </div>}
      </div>
      <div className="actions">
        {!isRefunding && <Button onClick={() => { props.onCancel(); }} className="cancel" type="button">Cancel</Button>}
        <Button isLoading={isRefunding} type="submit">Refund</Button>
      </div>
    </form>
  );
}

export default Refund;