import React from "react";
import { AxiosError } from "axios";
import { createRoot } from "react-dom/client";
import Toast, { ToastProps } from "./Toast";
import { IDog } from "@eagerdog/interfaces";

import "./Toast.scss";

interface ToastOptions {
  id?: string,
  title: string,
  content: string,
  duration?: number,
  errorDetails?: AxiosError,
  dog?: IDog,
  onDogEdit?(): void,
  canEdit?: boolean,
  type: string
}

export class ToastManager {
  private containerRef: HTMLDivElement;
  private toasts: ToastProps[] = [];
  private root:any;

  constructor() {
    const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
    const toastContainer = document.createElement("div") as HTMLDivElement;
    toastContainer.id = "toast-container-main";
    body.insertAdjacentElement("afterbegin", toastContainer);
    this.containerRef = toastContainer;
    this.root = createRoot(this.containerRef);
  }

  public show(options: ToastOptions): void {
    if (this.toasts.length > 2) {
      this.toasts[this.toasts.length - 1].destroy();
    }

    const toastId = Math.random().toString(36).substr(2, 9);
    const toast: ToastProps = {
      id: toastId,
      ...options,
      destroy: () => this.destroy(options.id ?? toastId),
    };

    this.toasts = [toast, ...this.toasts];

    this.render();
  }

  public destroy(id: string): void {
    this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
    this.render();
  }

  private render(): void {
    const toastsList = this.toasts.map((toastProps: ToastProps) => (
      <Toast key={toastProps.id} {...toastProps} />
    ));

    this.root.render(toastsList);
  }
}

export const toast = new ToastManager();