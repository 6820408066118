import React, { useState, createElement } from "react";
import Button from "src/components/Button/Button";

import "./Table.scss";

export interface IHeader {
  label: string,
  id: string,
  format?: string
}

export interface IRowAction {
  icon: string,
  options?: ITableDropAction[],
  onClick?(): void,
}

export interface ITableDropAction {
  label: string,
  onClick(): void
}

export interface ITableAction {
  text: string,
  onClick(): void
}

interface IProps {
  headers: IHeader[],
  data: any[],
  emptyMsg?: JSX.Element,
  onPageNext?(): void,
  onPagePrevious?(): void,
  limit?: number,
  skip?: number,
  action?: ITableAction
}

const Table: React.FC<IProps> = (props) => {
  const [toggleRow, setToggleRow] = useState<number>(-1);
  const [toggleIcon, setToggleIcon] = useState<number>(-1);

  return (
    <div className="Table">
      <div className="headers">
        {props.headers.map((h: IHeader, index: number) => {
          return (<div key={index} className={"header no-select " + h.id}><span>{h.label === "Actions" ? "" : h.label}</span><span className="sort"></span></div>);
        })}
      </div>
      <div className="data">
        {(props.data.length <= 0) && (props.emptyMsg ? props.emptyMsg : <div className="emptyTable"><span>No Records Found</span></div>)}
        {props.data.map((row: any, index: number) => {
          return (
            <div key={index} className="row">
              {Object.entries(row).map((d: any, index2: number) => {
                if (d[0] !== "id") {
                  if (d[0] === "actions") {
                    return (
                      <div key={index2} className="cell actions">
                        <ul className="actionList">
                          {d[1].map((action: IRowAction, actionIndex: number) => {
                            return (
                              <li key={actionIndex} onClick={
                                () => {
                                  if (action.onClick) {
                                    action.onClick();
                                  } else if (action.options) {
                                    if (toggleRow === index) {
                                      if (toggleIcon !== actionIndex) {
                                        setToggleIcon(actionIndex);
                                      } else {
                                        setToggleRow(-1);
                                        setToggleIcon(-1);
                                      }

                                    } else {
                                      setToggleIcon(actionIndex);
                                      setToggleRow(index);
                                    }
                                  }
                                }
                              }>
                                <div className="iconWrap">
                                  <div className={"icon " + action.icon}></div>
                                  {action.options && <ul className={toggleRow === index && actionIndex === toggleIcon ? "actionOptions no-select" : "actionOptions hidden no-select"}>
                                    {action.options.map((option: ITableDropAction, index3: number) => {
                                      return (<li key={index3} onClick={() => { option.onClick(); }}>{option.label}</li>);
                                    })}
                                  </ul>}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  } else {                    
                      return (<div
                        key={index2}
                        title={typeof d[1] === 'object' ? undefined : d[1]}
                        className={"cell " + d[0]}>
                        <div className="cellWrap">
                          <span>
                            {typeof d[1] === 'object' ?
                              createElement(
                                d[1].type,
                                { className: d[1].props.className ? d[1].props.className : undefined, ...d[1].props},
                                Array.isArray(d[1].props.children) ? d[1].props.children.map((ch: any, index3: number) =>  {
                                  return createElement(ch.type, { key: "ce "+ Math.random().toString().substr(2, 5) }, ch.props.children)
                                })
                                : d[1].props.children)
                              : d[1]}
                          </span>
                        </div>
                      </div>);                    
                  }
                } else {
                  return (null);
                }
              })}
            </div>
          );
        })}
        {props.action && <div className="tableAction"><Button onClick={props.action.onClick}>{props.action.text}</Button></div>}
      </div>
      {(props.onPageNext && props.onPagePrevious && (props.data.length > 0)) && <div className="tableControls">
        {(props.skip !== 0) && <div className="control previous"><span className="no-select" onClick={props.onPagePrevious}>Previous</span></div>}
        {(props.data.length === props.limit) && <div className="control next"><span className="no-select" onClick={props.onPageNext}>Next</span></div>}
      </div>}
    </div>
  );
};

export default Table;