import React, { useState, useEffect, useCallback } from "react";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { helperService } from "../../../services/helper.service";

import { IUser, IAddress, IDogCoownerInfo, UserUpdateDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Phone from "src/components/Phone/Phone";
import Dropdown, { IOption, States, Provinces } from "src/components/Dropdown/Dropdown";

import flagIcon from "src/assets/icons/flag.svg";

import "./UserAddress.scss";

interface IProps {
  user: IUser | IDogCoownerInfo | UserUpdateDto,
  onChange(address: IAddress, phone: string): void,
  onBehalf?: boolean,
  required?: boolean
}

export interface IAddressError {
  valid: boolean,
  message?: string
}

export const isValidPhone = (phone: string | undefined) => {
  if (phone !== undefined && !isPossiblePhoneNumber(phone)) {
    return { valid: false, message: "Please enter a valid phone number" };
  }

  return { valid: true };
}

export const isValidAddress = (address: IAddress | undefined) => {
  if (address === undefined ||
      address.city?.length === 0 ||
      address.country?.length === 0 ||
      address.line1?.length === 0 ||
      address.postal_code?.length === 0 ||
      address.state?.length === 0) {
    return { valid: false, message: "Please enter a valid handler address" };
  }

  if (address.country === "Canada" && address.postal_code !== undefined) {
    let regex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

    if (!regex.test(address.postal_code)) {
      return { valid: false, message: "Please enter a valid handler postal code" };
    }
  } else if (address.country === "USA" && address.postal_code !== undefined) {
    let regex = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/);

    if (!regex.test(address.postal_code)) {
      return { valid: false, message: "Please enter a valid handler zip code" };
    }
  }

  return { valid: true };
}

const UserAddress: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const [line1, setLine1] = useState<string>("");
  const [line2, setLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<IOption>({ value: "Select a Country", id: "" });
  const [state, setState] = useState<IOption>({ value: "Select a State", id: "" });
  const [postalCode, setPostalCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const hydrateUserData = useCallback(() => {
    if (props.onBehalf === false || props.onBehalf === undefined) {
      setLine1(props.user?.address?.line1 || "");
      setLine2(props.user?.address?.line2 || "");
      setCity(props.user?.address?.city || "");
      setCountry(props.user?.address?.country ? { id: props.user?.address?.country, value: props.user?.address?.country} : { value: "Select a Country", id: "" });
      setState(props.user?.address?.state ? Provinces.filter((o) => o.id === props.user?.address?.state)[0] || States.filter((o) => o.id === props.user?.address?.state)[0] || Provinces[0] : { value: "Select a State", id: "" });
      setPostalCode(props.user?.address?.postal_code || "");
      setPhone(props.user?.phone || "");
    }
  }, [props]);

  useEffect(() => {
    if (!loaded) {
      hydrateUserData(); 
      setLoaded(true);
    }
  }, [loaded, hydrateUserData]);

  useEffect(() => {
    if (changesMade) {
      props.onChange({
        line1, 
        line2, 
        city,
        state: state.id, 
        country: country.id, 
        postal_code: postalCode,
      }, phone);

      setChangesMade(false);
    }
  }, [props, changesMade, setChangesMade, line1, line2, city, country, state, postalCode, phone]);

  const pCountry = helperService.usePrevious(country.id);

  useEffect(() => {
    if (loaded && pCountry !== "" && pCountry !== country.id) {
      setState({ value: "Select a State", id: "" })
    }
  }, [loaded, country, pCountry, setState]);

  return (
    <div className="UserAddress">
      <div className="addressWrap">
        <div className="inputRow">
          <Input required={props.required !== undefined ? props.required : true} label="Address Line 1" id="line1" type="text" onChange={(e) => { e.stopPropagation(); setChangesMade(true);  setLine1(e.target.value); }} defaultValue={line1} placeholder="Address Line 1" />
          <Input label="Line 2" id="line2" type="text" onChange={(e) => { e.stopPropagation(); setChangesMade(true);  setLine2(e.target.value); }} defaultValue={line2} placeholder="Line 2" />
        </div>
        <div className="inputRow">
          <Input label="City" id="city" required={props.required !== undefined ? props.required : true} type="text" onChange={(e) => { e.stopPropagation(); setChangesMade(true);  setCity(e.target.value); }} defaultValue={city} placeholder="City" />
          <Input label="Postal/Zip Code" required={props.required !== undefined ? props.required : true} id="postalCode" type="text" onChange={(e) => { e.stopPropagation(); setChangesMade(true);  setPostalCode(e.target.value); }} defaultValue={postalCode} placeholder="Postal Code" /> 
        </div>
        <div className="inputRow">
          <Dropdown value={country} onChange={(e: any, value: IOption) => { setChangesMade(true); setCountry(value); setState(props.user?.address?.state ? Provinces.filter((o) => o.id === props.user?.address?.state)[0] || States.filter((o) => o.id === props.user?.address?.state)[0] || Provinces[0] : { value: "Select a State/Province", id: "" }); }} icon={flagIcon} label="Country" options={[{ value: "Canada", id: "Canada" }, { value: "USA", id: "USA" }]} placeholder="Choose your country of residence" />
          <Dropdown value={state} onChange={(e: any, value: IOption) => { setState(value); setChangesMade(true);  }} icon={flagIcon} label="Province / State" options={country.value === "USA" ? States : Provinces} placeholder="Choose your state/province of residence" />
        </div>
        <div className="inputRow">
          <Phone label="Phone Number" required={props.required !== undefined ? props.required : true} value={phone} onChange={(phone: any) => { setPhone(phone); setChangesMade(true);  }} />
        </div>
      </div>
    </div>
  );
};

export default UserAddress;
