import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../../styles/Signup.scss";

import logo from "../../assets/logo.svg";

import Input from "../../components/Input/Input";
import Button from "src/components/Button/Button";
import { toast } from "../../components/Toast/ToastManager";

import { helperService, IDogPortrait } from "../../services/helper.service";
import { apiService } from "../../services";

import { EmailVerificationDto, IUser } from "@eagerdog/interfaces";

interface IProps {}

const VerifyEmail: React.FC<IProps> = (props) => {
  const user:IUser = helperService.getUser();
  const navigate = useNavigate();

  const [v1, setV1] = useState("");
  const [v2, setV2] = useState("");
  const [v3, setV3] = useState("");
  const [v4, setV4] = useState("");

  const [pasted, setPasted] = useState(false);
  const [onLoad, setOnLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goToNext = (e:any) => {
    let t:string = "v2";

    let noNoKeys:string[] = [
      "Backspace",
      "Tab",
      "ShiftLeft",
      "ShiftRight",
      "ControlLeft",
      "ControlRight",
      "KeyA"
    ];

    switch(e.target.id) {
      case "v2":
        t = "v3";
      break;
      case "v3":
        t = "v4";
      break;
    }

    if (!noNoKeys.includes(e.code) && !isNaN(e.target.value)) {
      document.getElementById(t)?.focus();
    }
  }

  const isPasting = (e: any) => {
    setPasted(true);
    let clipboardData = e.clipboardData || e.originalEvent.clipboardData;
    let code:string = clipboardData.getData('Text');

    if (code.length === 4) {
      setV1(code[0]);
      setV2(code[1]);
      setV3(code[2]);
      setV4(code[3]);
    }
  }

  const resend = useCallback(() => {
    apiService.resendEmailVerification();

    toast.show({
      title: "Email Verification",
      content: "A verficiation email has been sent to your email",
      duration: 10000,
      type: "success"
    });
  }, []);

  const verify = useCallback((silent: boolean = false) => {
    if (v1.length > 0 && v2.length > 0 && v3.length > 0 && v4.length > 0) {
      let payload:EmailVerificationDto = {
        email_verification_code: v1 + v2 + v3 + v4
      }

      setIsLoading(true);

      apiService.verifyEmail(payload).then((response) => {
        const queryParameters = new URLSearchParams(window.location.search);
        const redirect = queryParameters.get("r");
        
        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/");
        }
        
        toast.show({
          title: "Email Verification",
          content: "Your email has been successfully verified!",
          duration: 10000,
          type: "success"
        });
      }).catch((error) => {
        toast.show({
          title: "Email Verification",
          content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
          duration: 10000,
          type: "fail"
        });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [navigate, v1, v2, v3, v4]);

  useEffect(() => {
    if (pasted) {
      verify();
    }
  }, [v4, pasted, verify]);

  useEffect(() => {
    if (!onLoad) {
      if (user?.is_email_verified) {
        toast.show({
          title: "Email Verification",
          content: "Your email is already verified",
          duration: 10000,
          type: "success"
        });

        navigate("/account");
      }
      
      const queryParameters = new URLSearchParams(window.location.search);
      const sendOnLoad = queryParameters.get("sendOnLoad");

      if (sendOnLoad) {
        resend();
      }

      setOnLoad(true);
    }
  }, [onLoad, resend, navigate, user]);

  return (
    <div className="VerifyEmail Signup">
      <Helmet>
        <title>Reset Password</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="images">
        <div className="logo"><Link to="/"><img src={logo} alt="Eager Dog" /></Link></div>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }} className="dogCards">
          <div className="cardRow">
            {helperService.topDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
          <div className="cardRow">
            {helperService.bottomDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
        </motion.div>
        <motion.div initial={{ opacity: 0, translateY: "-300px" }} whileInView={{ opacity: 1, translateY: "-50px" }} transition={{ delay: 0.5 }} className="dogBanner">
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 1 }} className="bannerInner">
            <div className="small">The Best Place</div>
            <div className="large">for your <span>Dog</span></div>
            <div className="dogWrap">
              <div className="portrait"><img src={helperService.allDogs[1].image} alt={"Dog Portrait of  " + helperService.allDogs[1].name} /></div>
            </div>
            <div className="dogName">{helperService.allDogs[1].name}</div>
          </motion.div>
        </motion.div>
      </div>
      <div className="content">
        <div className="inner">
          <div className="logo"><img src={logo} alt="Eager Dog" /></div>
          <h1>Verify Email</h1>
          <div className="form">
            <form onSubmit={(e) => { e.preventDefault(); }}>
              <p>A verification code for the email has been sent to your email. Please enter the 4-digit code.</p>
              <div className="verifyInputs">
                <div className="verifyWrap"><Input defaultValue={v1} type="text" pattern="\d*" id="v1" onChange={(e:any) => { e.preventDefault(); setV1(e.target.value); }} onPaste={isPasting} onKeyUp={(e:any) => { goToNext(e); }} maxLength={1} label="" /></div>
                <div className="verifyWrap"><Input defaultValue={v2} type="text" pattern="\d*" id="v2" onChange={(e:any) => { e.preventDefault(); setV2(e.target.value); }} onKeyUp={(e:any) => { goToNext(e); }} maxLength={1} label="" /></div>
                <div className="verifyWrap"><Input defaultValue={v3} type="text" pattern="\d*" id="v3" onChange={(e:any) => { e.preventDefault(); setV3(e.target.value); }} onKeyUp={(e:any) => { goToNext(e); }} maxLength={1} label="" /></div>
                <div className="verifyWrap"><Input defaultValue={v4} type="text" pattern="\d*" id="v4" onChange={(e:any) => { e.preventDefault(); setV4(e.target.value); }} onKeyUp={(e:any) => { verify(true); }} maxLength={1} label="" /></div>
              </div>
              <div className="actions">
                <Button type="submit" isLoading={isLoading}>Verify</Button>
              </div>
              <div className="register">Didn't recieve an email? <span onClick={() => { resend(); }}>resend email</span></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
