import React from "react";
import { createRoot } from "react-dom/client";
import Download from "./Download";

import "./Download.scss";

export interface DownloadOptions {
  type: string,
  registrantId?: string,
  dogId?: string,
  eventId?: string,
  showId?: string,
  transactionId?: string,
  showType?: string,
  showElement?: string,
  showLevel?: string,
  showSection?: string,
  labelsToSkip?: number
}

export class DownloadManager {
  private containerRef: HTMLDivElement;
  private downloads: DownloadOptions[] = [];
  private downloadInterval: any;

  private root:any;

  constructor() {
    const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
    const downloadContainer = document.createElement("div") as HTMLDivElement;
    downloadContainer.id = "download-container-main";
    body.insertAdjacentElement("afterbegin", downloadContainer);
    this.containerRef = downloadContainer;
    this.root = createRoot(this.containerRef);
  }

  public pushDownload(options: DownloadOptions): void {
    this.downloads.push(options);
    this.render();
  }

  public shiftDownload(): void {
    this.downloads.shift();
    this.render();
  }

  public destroy(): void {
    this.render();
  }

  private render(): void {
    const downloadContent = <Download files={this.downloads} />;
    this.root.render(downloadContent);
  }
}

export const download = new DownloadManager();