import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace Agility {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
       type: "checkbox", label: Constants.dog_class_element.performance_agility, multiSelect: true, options: [
         { value: Constants.dog_class_element_level.beginner_a, id: Constants.dog_class_element_level.beginner_a },
         { value: Constants.dog_class_element_level.beginner_b, id: Constants.dog_class_element_level.beginner_b },
         { value: Constants.dog_class_element_level.intermediate_a, id: Constants.dog_class_element_level.intermediate_a },
         { value: Constants.dog_class_element_level.intermediate_b, id: Constants.dog_class_element_level.intermediate_b },
         { value: Constants.dog_class_element_level.excellent, id: Constants.dog_class_element_level.excellent }
       ]
     }]
   }];
}