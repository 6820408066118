import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './DatePick.scss';

interface IProps {
  onChange: any,
  value: any,
  label?: string,
  minDate?: Date,
  maxDate?: Date,
  disabled?: boolean,
  showTimeSelect?: boolean,
  className?: string,
  required?: boolean
}

const DatePick: React.FC<IProps> = (props) => {
  return(
    <div className={props.className ? "DatePick " + props.className : "DatePick"}>
      <div className="label"><span>{props.label ? props.label : "Date"} (MM/DD/YYYY)</span></div>
      <div className="dateInner">
        <DatePicker
          required={props.required || false}
          minDate={props.minDate ? new Date(props.minDate) : undefined}
          maxDate={props.maxDate ? new Date(props.maxDate) : undefined}
          dateFormat={props.showTimeSelect ? "MM/dd/yyyy h:mm a" : "MM/dd/yyyy"}
          showTimeSelect={props.showTimeSelect ? props.showTimeSelect : false}
          disabled={props.disabled ? props.disabled : false}
          selected={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
}

export default DatePick;