import React, { useEffect, useState } from 'react';
import { Constants } from "@eagerdog/Constants";
import { IShowRegistrationSummary, IEvent, IShow } from "@eagerdog/interfaces";
import { AxiosError } from 'axios';
import moment from "moment";

import { toast } from "src/components/Toast/ToastManager";
import { apiService } from "../../services/api.service";

import Button from "src/components/Button/Button";
import FormBuilder, { IDogClassElement } from "src/components/FormBuilder/FormBuilder";

import './ChangeElements.scss';

interface IProps {
  event: IEvent | undefined,
  registration: IShowRegistrationSummary,
  onComplete(): void,
  onClose(): void
}

interface IShowClass extends IShow {
  dog_classes?: any[]
}

const ChangeElements: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const [scoreIds, setScoreIds] = useState<string[]>([]);
  const [shows, setShows] = useState<IShowClass[]>();

  const isValidClasses = () => {
    if (props.registration.show_type !== Constants.show_type.nosework) {
      return true;
    }

    let _shows = JSON.parse(JSON.stringify(shows));

    for (let s in _shows) {
      for (let c in _shows[s].dog_classes) {
        if (_shows[s].dog_classes[c].section !== undefined && _shows[s].dog_classes[c].section === "None") {
          toast.show({
            title: "Change Elements",
            content: "Please select a section for " + _shows[s].dog_classes[c].show_element + " ("+ _shows[s].show_type + " " + _shows[s].show_name + " " + moment(_shows[s].show_date).format("MMMM Do") +")",
            duration: 10000,
            type: "fail"
          });

          return false;
        }
      }
    }

    return true;
  }

  const getClassCount = () => {
    let amt:number = 0;
    let _shows = JSON.parse(JSON.stringify(shows));

    for (let s in _shows) {
      amt += _shows[s].dog_classes.length;
    }

    return amt;
  }

  const submitChange = () => {
    if (props.event) {
      if (getClassCount() === scoreIds.length) {
        let _shows = JSON.parse(JSON.stringify(shows));

        let _payload:any = {
          dog_classes: []
        }

        for (let s in _shows) {
          for (let c in _shows[s].dog_classes) {
            let _dog_class:any = {
              score_id: _shows[s].dog_classes[c].score_id,
              show_id: _shows[s]._id,
              dog_class: {
                section: _shows[s].dog_classes[c].section,
                show_element: _shows[s].dog_classes[c].show_element,
                level: _shows[s].dog_classes[c].level
              }
            };

            _payload.dog_classes.push(_dog_class);
          }
        }

        apiService.dogClassUpdates(props.event._id, _payload).then((response) => {
          if (response === undefined) {
            toast.show({
              title: "Change Elements",
              content: "Element change for " + props.registration.dog.call_name + " successful",
              duration: 10000,
              type: "success"
            });

            props.onClose();
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Change Elements",
            content: "Failed to change elements for " + props.registration.dog.call_name,
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });
        });
      } else {
          toast.show({
            title: "Change Elements",
            content: "Please select exactly " + scoreIds.length + " elements (currently have " + getClassCount() + ")",
            duration: 10000,
            type: "fail"
          });
      }
    }
  }

  useEffect(() => {
    if (!loaded) {
      apiService.getClubEventShows(props.registration.club_id, props.registration.event_id, {
        query: {
          $and: []
        }
      }).then((response) => {
        if (response.length > 0) {
          let _shows:IShowClass[] = [];

          for (let s in response) {
            let _show:IShowClass = { ...response[s], dog_classes: [] };
            _shows.push(_show);
          }

          // setShow(response[0]);

          let _query:any = {
            query: {
              "$and": [{
                attribute_name: "dog_id",
                attribute_value: {
                  operator: "$eq",
                  value: props.registration.dog_id
                }
              }]
            },
            sort: []
          };

          apiService.getEventScores(props.registration.event_id, _query).then((scoreResponse) => {
            let scoreIds:string[] = [];

            for (let i in scoreResponse) {
              for (let s in _shows) {
                if (_shows[s]._id === scoreResponse[i].show_id && scoreResponse[i].dog_class) {
                  _shows[s].dog_classes?.push({ ...scoreResponse[i].dog_class, id: Math.random().toString(36).substring(2, 8), score_id: scoreResponse[i]._id });
                  break;
                }
              }

              scoreIds.push(scoreResponse[i]._id);
            }

            setScoreIds(scoreIds);
            setShows(_shows);
            setLoaded(true);
          }).catch((e: AxiosError) => {
            toast.show({
              title: "Get Dog Classes",
              content: "Failed to get Dog Classes",
              duration: 10000,
              errorDetails: e,
              type: "fail"
            });
          });
        }
      });
    }
  }, [loaded, props.registration, props.event?.sanctioning_club]);

  return (
    <form className="ChangeElements" onSubmit={(e) => { e.preventDefault(); if (isValidClasses()) { submitChange(); } }}>
      <div className="title">
        <span>Change Elements</span>
        <span>{props.registration.dog.call_name}</span>
      </div>
      <div className="forms">
        {loaded && shows && shows.length > 0 && shows.map((s: IShowClass, index: number) => {
          return(
            <div key={index + "form"} className="formContainer">
              <div className="formTitle">{s.show_type +" "+ s.show_name + " (" + moment(s.show_date).format("MMMM Do") +")"}</div>
              {s.dog_classes && <FormBuilder 
                show={s}
                event={props.event}
                dog={props.registration.dog}
                classes={s.dog_classes}
                edit={true}
                overrideDisable={true}
                onChange={
                  (newClasses: IDogClassElement[]) => {
                    let _newClasses:IDogClassElement[] = JSON.parse(JSON.stringify(newClasses));
                    let _shows = JSON.parse(JSON.stringify(shows));
                    let scoresInUse:string[] = [];

                    for (let i in _shows) {
                      if (_shows[i]._id === s._id) {
                        _shows[i].dog_classes = _newClasses;
                        break;
                      }
                    }

                    for (let i in _shows) {
                      for (let c in _shows[i].dog_classes) {
                        if (_shows[i].dog_classes[c].score_id !== undefined) {
                          scoresInUse.push(_shows[i].dog_classes[c].score_id || "");
                        }
                      }
                    }

                    if (scoresInUse.length !== scoreIds.length) {
                      for (let i in _shows) {
                        for (let c in _shows[i].dog_classes) {
                          for (let s in scoreIds) {
                            if (_shows[i].dog_classes[c].score_id === undefined && !scoresInUse.includes(scoreIds[s])) {
                              _shows[i].dog_classes[c].score_id = scoreIds[s];
                              scoresInUse.push(scoreIds[s]);
                            }
                          }
                        }
                      }
                    }

                    setShows(_shows);
                  }
                }
              />}
            </div>
          );
        })}
      </div>
      <div className="actions">
        <Button type="button" onClick={() => { props.onClose(); }}>Cancel</Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

export default ChangeElements;
