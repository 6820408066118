import React from "react";

import "./RadioList.scss";

export interface IRadioListAction {
  label: string,
  onClick(): void
}

export interface IRadioListItem {
  id: string,
  label: string,
  action: IRadioListAction
}

interface IProps {
  list: IRadioListItem[],
  selected: number,
  onSelect(index: number): void,
}

const RadioList: React.FC<IProps> = (props) => {
  return (
    <div className="RadioList">
      {props.list.map((item: IRadioListItem, index:number) => {
        return(
          <div className="radioListItem" key={index + "-" + item.id}>
            <div onClick={() => { props.onSelect(index); }} className="radioIcon"><div className={"icon " + (props.selected === index ? "radioFilled": "radioEmpty")}></div></div>
            <div onClick={() => { props.onSelect(index); }} className="radioListLabel"><span>{item.label}</span></div>
            <div className="radioListAction"><span onClick={() => { item.action.onClick(); }}>{item.action.label}</span></div>
          </div>
        );
      })}
    </div>
  );
};

export default RadioList;
