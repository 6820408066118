import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace RallyObedience {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
      type: "checkbox", label: Constants.dog_class_element.performance_rally_obedience, multiSelect: true, options: [
          { value: Constants.dog_class_element_level.ro1_a, id: Constants.dog_class_element_level.ro1_a },
          { value: Constants.dog_class_element_level.ro1_b, id: Constants.dog_class_element_level.ro1_b },
          { value: Constants.dog_class_element_level.ro1_c, id: Constants.dog_class_element_level.ro1_c },
          { value: Constants.dog_class_element_level.ro2_a, id: Constants.dog_class_element_level.ro2_a },
          { value: Constants.dog_class_element_level.ro2_b, id: Constants.dog_class_element_level.ro2_b },
          { value: Constants.dog_class_element_level.ro3_a, id: Constants.dog_class_element_level.ro3_a },
          { value: Constants.dog_class_element_level.ro3_b, id: Constants.dog_class_element_level.ro3_b },
          { value: Constants.dog_class_element_level.rom_a, id: Constants.dog_class_element_level.rom_a },
          { value: Constants.dog_class_element_level.rom_b, id: Constants.dog_class_element_level.rom_b }
       ]
    }]
   }];
}