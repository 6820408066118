import React, { useState } from 'react';
import { motion } from "framer-motion";
import ReactDOM from 'react-dom';

import './Modal.scss';

interface IProps {
  isShown: boolean,
  hide: () => void,
  modalContent: JSX.Element,
  className?: string
  expand?: boolean
}

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const toggle = () => {
    if (isShown && document.body.getElementsByClassName("Modal").length < 2) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }

    setIsShown(!isShown);
  }

  return { isShown, toggle };
};

export const Modal: React.FC<IProps> = (props) => {
  const modal = (
    <motion.div initial={{ opacity: 0, }} whileInView={{ opacity: 1 }} transition={{ duration: 0.1 }} className={props.className ? "Modal " + props.className: "Modal"}>
      <motion.div initial={{ opacity: 0, y: 100, }} whileInView={{ opacity: 1, y: 0 }} transition={{ type:"spring", duration: 0.3, delay: 0.25 }} className="inner" onClick={(e) => { e.stopPropagation(); }}>
        <div className="actionWrap">{props.expand && <div onClick={() => { window.location.reload(); }} className="expand">Open Event Page</div>}<div className="close" onClick={props.hide}></div></div>
        {props.modalContent}
      </motion.div>
    </motion.div>
  );

  return props.isShown ? ReactDOM.createPortal(modal, document.body) : null;
}