import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace ABIConformation {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
      type: "dropdown", flex: 2, label: Constants.dog_class_element.conformation, options: [
        { value: "None", id: "None" },
        { value: Constants.dog_class_element_level.abi_3_6_months_puppy, id: Constants.dog_class_element_level.abi_3_6_months_puppy },
        { value: Constants.dog_class_element_level.abi_6_9_months_puppy, id: Constants.dog_class_element_level.abi_6_9_months_puppy },
        { value: Constants.dog_class_element_level.abi_9_12_months_puppy, id: Constants.dog_class_element_level.abi_9_12_months_puppy },
        { value: Constants.dog_class_element_level.abi_12_18, id: Constants.dog_class_element_level.abi_12_18 },        
        { value: Constants.dog_class_element_level.abi_open, id: Constants.dog_class_element_level.abi_open },        
        // { value: Constants.dog_class_element_level.abi_altered, id: Constants.dog_class_element_level.abi_altered },
        { value: Constants.dog_class_element_level.abi_jr_champion, id: Constants.dog_class_element_level.abi_jr_champion },
        { value: Constants.dog_class_element_level.abi_champion, id: Constants.dog_class_element_level.abi_champion },
        { value: Constants.dog_class_element_level.abi_master_champion, id: Constants.dog_class_element_level.abi_master_champion },
        { value: Constants.dog_class_element_level.abi_grand_champion_master, id: Constants.dog_class_element_level.abi_grand_champion_master },
        { value: Constants.dog_class_element_level.abi_supreme_champion, id: Constants.dog_class_element_level.abi_supreme_champion },
        { value: Constants.dog_class_element_level.abi_ultimate_champion, id: Constants.dog_class_element_level.abi_ultimate_champion },
        { value: Constants.dog_class_element_level.abi_altered, id: Constants.dog_class_element_level.abi_altered }
      ]
    }]
  }, {
     allowDuplicates: false,
     inputs: [{
       type: "checkbox", label: Constants.dog_class_element.conformation_non_licensed, multiSelect: true, options: [
         { value: Constants.dog_class_element_level.veteran, id: Constants.dog_class_element_level.veteran },
         { value: Constants.dog_class_element_level.brace, id: Constants.dog_class_element_level.brace },
         //{ value: Constants.dog_class_element_level.abi_best_of_breed, id: Constants.dog_class_element_level.abi_best_of_breed },
         //{ value: Constants.dog_class_element_level.abi_bred_by_exibitor, id: Constants.dog_class_element_level.abi_bred_by_exibitor },
       ]
     }]
   }];
}