import React from 'react';
import './Button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isLoading?: boolean,
	children: React.ReactNode,
	variant?: "default" | "secondary" | "tertiary"
}

const Button: React.FC<ButtonProps> = ({ isLoading = false, children, variant = "default", ...nativeButtonProps }) => {
	return (
		<button
			{...nativeButtonProps}
			disabled={nativeButtonProps.disabled || isLoading}
			className={`Button ${variant} ${nativeButtonProps.className} ${isLoading ? 'loading' : ''}`}
		>
			{isLoading ? <div id="loading"></div> : <span>{children}</span>}
		</button>
	);
};

export default Button;
