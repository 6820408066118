import axios from "axios";
import { localStorageService } from "./local-storage.service";
import { EagerdogErrors } from "@eagerdog/constants";
import { Config } from "@eagerdog/config";

// ---------------
let failedQueue: Array<{
  resolve: (token: string | null) => void;
  reject: (err: any) => void;
}> = [];

// ---------------
// Processes any outstanding requests in the failed queue.
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// ---------------
let isRefreshing = false;

// -----------------
axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getItem(localStorageService.ACCESS_TOKEN);

    if (token) {
      config.headers!["Authorization"] = "Bearer " + token;
    }

    // needed for cookies to be sent back
    config.withCredentials = true;

    return config;
  },

  (error) => {
    Promise.reject(error);
  }
);

// ---------------
axios.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;

    if (
      error.response?.data?.eagerdog_error_code ===
      EagerdogErrors.AUTH.ACCESS_TOKEN_EXPIRED
    ) {
      if (originalRequest._retried) {
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;
      originalRequest._retried = true;
      // fetch the token
      return new Promise((resolve, reject) => {
        axios
          .get(`${Config.web.REACT_APP_API_URL}/api/auth/refresh`)
          .then((result) => {
            const accessToken = result.data.access_token;
            localStorageService.setItem(
              "access_token",
              result.data.access_token
            );
            isRefreshing = false;

            originalRequest.headers["Authorization"] = "Bearer " + accessToken;
            resolve(axios(originalRequest));
            processQueue(null, accessToken);
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
            window.location.href = "/logout?session_expired=1";
          });
      });
    }

    return Promise.reject(error);
  }
);

// ---------------
export namespace httpService {
  export const get = axios.get;
  export const post = axios.post;
  export const put = axios.put;
  export const delete_ = axios.delete;
}
