import React, { useState, useEffect }from "react";

import HorizontalScroll from "src/components/HorizontalScroll/HorizontalScroll";
import Button from "src/components/Button/Button";

import "./TabContent.scss";

export interface ITab {
  name: string,
  actions: ITabAction[]
}

export interface ITabAction {
  label: string,
  onClick(): void
}

interface IProps {
  activeTab: number,
  setTab(index: number): void,
  tabs: ITab[],
  children: boolean | JSX.Element[] | JSX.Element,
  loading?: boolean,
  defaultTab?: number
}

export const useTabContent = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const setTab = (index: number) => {
    setActiveTab(index);
  }

  return { activeTab, setTab };
}

const TabContent: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      if (props.defaultTab) {
        props.setTab(props.defaultTab);
      }

      setLoaded(true);
    }
  }, [loaded, props, setLoaded]);

  if (!props.loading) {
    return (
      <div className="TabContent">
        <HorizontalScroll className="tabs">
          {props.tabs.map((t:ITab, index:number) => {
            return(<li key={index} onClick={() => { props.setTab(index); }} className={index === props.activeTab ? "active no-select" : "no-select"}>{t.name}</li>);
          })}
          {props.tabs[props.activeTab].actions && props.tabs[props.activeTab].actions.map((action:ITabAction, tIndex:number) => {
            return(<li key={tIndex} className="actions"><Button onClick={() => { action.onClick(); }}>{action.label}</Button></li>);
          })}
        </HorizontalScroll>
        <div className="content">
          {!Array.isArray(props.children) && <div className="tabContent active" data-index={0}>{props.children}</div>}
          {Array.isArray(props.children) && props.children.map((child:JSX.Element, index:number) => {
            return(
              <div key={index} data-index={index} className={props.activeTab === index ? "tabContent active" : "tabContent"}>
                {child}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return(
      <div className="TabContent skeleton">
        <div className="details"></div>
      </div>
    );
  }
};

export default TabContent;