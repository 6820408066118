import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace ABICountryRatRace {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
      inputs: [{
        type: "dropdown", flex: 2, label: Constants.dog_class_element.performance_country_rat_race, options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_element_level.cubs, id: Constants.dog_class_element_level.cubs },
          { value: Constants.dog_class_element_level.scouts, id: Constants.dog_class_element_level.scouts },
          { value: Constants.dog_class_element_level.huntsman, id: Constants.dog_class_element_level.huntsman },
          { value: Constants.dog_class_element_level.huntsmaster, id: Constants.dog_class_element_level.huntsmaster },
          { value: Constants.dog_class_element_level.field_master, id: Constants.dog_class_element_level.field_master }
        ]
      }]
    }];
}