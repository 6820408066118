import React, { useState, useRef, useEffect } from "react";
import { helperService } from "../../services/helper.service";

import "./Input.scss";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: string,
  label?: string,
  icon?: string,
  eye?: string,
  onPaste?(e: any): void,
  onClickEye? (): void
}

const Input: React.FC<IProps> = (props) => {
  const [inputClass, setInputClass] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const inputPreClass = props.icon ? "Input hasIcon" : "Input noIcon";

  const setFocus = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      setInputClass("focused");
    }
  }

  const onBlur = () => {
    setInputClass("");
  }

  const hasValue = () => {
    if (inputRef && inputRef.current) {
      if (inputRef.current.value.length > 0) {
        return true;
      }
    }

    return false;
  }

  const prevDefault = helperService.usePrevious(props.defaultValue);

  useEffect(() => {
    if (inputRef.current && (props.defaultValue !== prevDefault)) {
      inputRef.current.value = props.defaultValue ? props.defaultValue : inputRef.current.value;
    }
  }, [inputRef, props.defaultValue, prevDefault]);

  return (
    <div onClick={() => { if (!props.disabled) { setFocus(); } }} className={"focused " + props.placeholder ? inputPreClass +" "+ inputClass : hasValue() ? inputPreClass +" "+ inputClass + " noPlaceholder focused" : inputPreClass +" "+ inputClass + " noPlaceholder"}>
      {props.label ? <label><span>{props.label}</span></label> : null}
      {props.icon && <img className="icon" alt="input icon" src={props.icon} />}
      <input
        defaultValue={props.defaultValue ? props.defaultValue : ""}
        onBlur={onBlur}
        onPaste={(e:any) => { if (props.onPaste) { props.onPaste(e) } }}
        ref={inputRef}
        {...props}
      />
      {props.eye && <img className="eye" alt="input icon" src={props.eye} onClick={props.onClickEye} />}
    </div>
  );
};

export default Input;
