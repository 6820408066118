import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace AKCRallyObedience {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.performance_rally_obedience,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.novice_a,
              id: Constants.dog_class_element_level.novice_a,
            },
            {
              value: Constants.dog_class_element_level.novice_b,
              id: Constants.dog_class_element_level.novice_b,
            },
            {
              value: Constants.dog_class_element_level.intermediate,
              id: Constants.dog_class_element_level.intermediate,
            },
            {
              value: Constants.dog_class_element_level.advanced_a,
              id: Constants.dog_class_element_level.advanced_a,
            },
            {
              value: Constants.dog_class_element_level.advanced_b,
              id: Constants.dog_class_element_level.advanced_b,
            },
            {
              value: Constants.dog_class_element_level.excellent_a,
              id: Constants.dog_class_element_level.excellent_a,
            },
            {
              value: Constants.dog_class_element_level.excellent_b,
              id: Constants.dog_class_element_level.excellent_b,
            },
            {
              value: Constants.dog_class_element_level.master,
              id: Constants.dog_class_element_level.master,
            },
            {
              value: Constants.dog_class_element_level.choice,
              id: Constants.dog_class_element_level.choice,
            },
            {
              value: Constants.dog_class_element_level.pairs,
              id: Constants.dog_class_element_level.pairs,
            },
            {
              value: Constants.dog_class_element_level.team,
              id: Constants.dog_class_element_level.team,
            },
            {
              value: Constants.dog_class_element_level.rae,
              id: Constants.dog_class_element_level.rae,
            },
            {
              value: Constants.dog_class_element_level.raem,
              id: Constants.dog_class_element_level.raem,
            }
          ],
        },
      ],
    },
  ];
}
