import React from "react";
import { Config } from "@eagerdog/config";
import { AxiosError } from 'axios';
import { UserUpdateDto, IAddress } from "@eagerdog/interfaces";

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { apiService } from "src/services/api.service";
import { localStorageService } from "src/services/local-storage.service";

import { toast } from "src/components/Toast/ToastManager";
import { isValidAddress, isValidPhone } from "../UserAddress/UserAddress";
import Button from "src/components/Button/Button";

import "./Checkout.scss";

interface IProps {
  redirect: string,
  onBehalf?: boolean,
  onAddCard?(): void,
  returnUrl?: string,
  userAddress?: IAddress,
  userPhone?: string,
}

const Checkout: React.FC<IProps> = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = React.useState(false);

  const confirmPayment = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    if (props.onBehalf) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: props.returnUrl || Config.web.REACT_APP_WEB_URL
        },
        redirect: "if_required"
      });

      if (error) {
        toast.show({
          title: "Event Registration",
          content: error.message || "Something went wrong when registering, please try again",
          duration: 10000,
          type: "fail"
        });

        setLoading(false);
      } else {
        if (props.onAddCard) {
          props.onAddCard();
        }
      }

    } else {
      if (hasAddress()) {
        if (!props.onBehalf || (props.onBehalf && isValidAddress(props.userAddress).valid)) {
          if (isValidPhone(props.userPhone).valid) {
              let updatedUser: UserUpdateDto = {
                address: props.userAddress,
                phone: props.userPhone
              };

              apiService.updateSelf(updatedUser).then((response) => {
                localStorageService.setItem(localStorageService.USER, JSON.stringify(response));
                startPayment();
              }).catch((e: AxiosError) => {
                toast.show({
                  title: "Update User Address",
                  content: "Unable to update user address, transaction can't continue.",
                  duration: 10000,
                  errorDetails: e,
                  type: "fail"
                });
              });

              const startPayment = async () => {
                const result = await stripe.confirmPayment({
                  elements,
                  confirmParams: {
                    return_url: props.redirect
                  }
                });

                if (result.error) {
                  toast.show({
                    title: "Event Registration",
                    content: result.error.message || "Something went wrong when registering, please try again",
                    duration: 10000,
                    type: "fail"
                  });
                }

                setLoading(false);
              }
          } else {
            toast.show({
              title: "Event Registration",
              content: isValidPhone(props.userPhone).message || "",
              duration: 10000,
              type: "fail"
            });

            setLoading(false);
          }
        } else {
          toast.show({
            title: "Event Registration",
            content: isValidAddress(props.userAddress).message || "",
            duration: 10000,
            type: "fail"
          });

          setLoading(false);
        }
      } else {
          toast.show({
            title: "Event Registration",
            content: "Please enter a full address",
            duration: 10000,
            type: "fail"
          });

          setLoading(false);
      }
    }
  };

  const hasAddress = () => {
    return props.userAddress && props.userAddress.line1 !== "" && 
    props.userAddress.city !== "" && props.userAddress.postal_code !== "" && 
    props.userAddress.country && props.userAddress.country.length > 0 && 
    props.userAddress.state && props.userAddress.state.length > 0;
  }

  return (
    <div className="Checkout">
      <div className="title">Payment Information</div>
      <PaymentElement options={{
        fields: {
          billingDetails: {
          }
        }
      }} />
      <div className="actions">
        <Button onClick={confirmPayment} disabled={loading} isLoading={loading}>{props.onBehalf ? "Add Card" : "Confirm Payment"}</Button>
      </div>
    </div>
  );
};

export default Checkout;
