import React from "react";
import { IShowRunTimeSlot } from "@eagerdog/interfaces";

import './TimeSlot.scss';

interface IProps {
  timeslot: IShowRunTimeSlot,
  className?: string
  onClick?(e: any): void
}

const TimeSlot: React.FC<IProps> = (props) => {
  return (
    <div onClick={props.onClick} className={"TimeSlot " + props.className || ""}>
      <span className="label">{props.timeslot.time_slot}</span>
      <span className="slots">{props.timeslot.num_available - props.timeslot.num_reserved} Slots Available</span>
      <span className="note">{props.timeslot.note ? props.timeslot.note : ''}</span>
    </div>
  );
};

export default TimeSlot;