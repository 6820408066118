import React from "react";
import { Helmet } from "react-helmet";

import "./PrivacyPolicy.scss";

interface IProps {}

const PrivacyPolicy: React.FC<IProps> = (props) => {
  return (
    <div className="PrivacyPolicy">
    <Helmet>
      <title>Privacy Policy</title>
        <meta name="description" content="Your privacy is critically important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform." />
        <meta name="robots" content="index, follow" />
    </Helmet>
    <div className="genericInner">
      <p>Effective Date: August 25th, 2024</p>

      <h1>Introduction</h1>

      <p>Welcome to Eager Dog! Your privacy is critically important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform and services. By accessing or using Eager Dog, you agree to the collection and use of information in accordance with this policy.</p>

      <h2>Information We Collect</h2>

      <p>We may collect and process the following types of information:</p>

      <ul>
        <li>Personal Information: When you register on our platform, we may collect personal details such as your name, email address, phone number, and any other information you voluntarily provide.</li>
        <li>Event Data: Information related to your participation in events, including registration details, event preferences, and any other related data.</li>
        <li>Technical Information: We automatically collect information about your device, IP address, browser type, and usage patterns when you interact with our platform.</li>
        <li>Cookies: We use cookies and similar tracking technologies to monitor the activity on our service and hold certain information.</li>
      </ul>

      <h2>How We Use Your Information</h2>

      <p>We use the collected data for the following purposes:</p>

      <ul>
        <li>To Provide and Maintain Our Service: To manage your account and deliver the services you request, including event registration and management.</li>
        <li>To Improve Our Services: We analyze usage data to improve the functionality and performance of our platform.</li>
        <li>To Communicate with You: To send you updates, notifications, and any other relevant communications about your account or our services.</li>
        <li>For Legal and Security Purposes: To comply with legal obligations, resolve disputes, and enforce our agreements.</li>
      </ul>

      <h2>Data Storage and Security</h2>

      <ul>
        <li>Data Storage Location: All data collected by Eager Dog is stored in Canada, utilizing Amazon Web Services (AWS) infrastructure.</li>
        <li>Data Security: We implement a variety of security measures, including encryption and access controls, to protect your personal data. Despite these measures, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
      </ul>

      <h2>Sharing and Disclosure of Information</h2>

      <p>We do not sell or rent your personal information to third parties. However, we may share your data with:</p>

      <ul>
        <li>Service Providers: Trusted third-party service providers who assist us in operating our platform and delivering our services, under strict confidentiality agreements.</li>
        <li>Legal Compliance: If required by law or in response to valid requests by public authorities, we may disclose your personal information.</li>
        <li>Business Transfers: In the event of a merger, acquisition, or asset sale, your personal data may be transferred to a new entity.</li>
      </ul>

      <h2>Your Rights</h2>

      <p>Depending on your location, you may have the following rights regarding your personal data:</p>

      <ul>
        <li>Access: You can request a copy of the personal data we hold about you.</li>
        <li>Correction: You can request corrections to your personal data if it is inaccurate or incomplete.</li>
        <li>Deletion: You can request the deletion of your personal data, subject to certain conditions.</li>
        <li>Objection: You can object to the processing of your data under certain circumstances.</li>
      </ul>

      <p>To exercise any of these rights, please contact us.</p>

      <h2>International Data Transfers</h2>

      <p>As all data is stored in Canada, your personal data may be subject to Canadian privacy laws. By using our services, you consent to the transfer of your data to Canada, where it will be processed and stored.</p>

      <h2>Changes to This Privacy Policy</h2>

      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

      <h2>Contact Us</h2>

      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

      <p>Eager Dog Privacy Office<br/>
      2967 Dundas St. W. #1664<br/>
      Toronto, ON M6P 1Z2<br/>
      privacy@eagerdog.com</p>


      <h2>Acceptance of This Policy</h2>

      <p>By using our platform, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our services.</p>

      <p>This Privacy Policy outlines how Eager Dog handles personal data, ensuring compliance with applicable privacy laws and maintaining transparency with users.</p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
