import {
  SubscriptionDto,
  OwnerRegistrationDto,
  ClubRegistrationDto,
  AuthDto,
  IUser,
  IAuthResponse,
  EmailVerificationDto,
  ISubscription,
  DogDto,
  IDog,
  QueryDto,
  IClub,
  EventDto,
  IEvent,
  IBreed,
  IShow,
  ShowDto,
  IEventSummary,
  //EventShowsDto,
  EventRegistrationCreateDto,
  ITransactionAmounts,
  ITransactionPaymentSummary,
  IEventRegistrationResponse,
  AccountLinkDto,
  IAccountStatus,
  IShowRegistrationSummary,
  IDiscount,
  DiscountDto,
  IEventRegistration,
  EventRegistrationUpdateDto,
  UserUpdateDto,
  UpdatePasswordDto,
  IUserTransactionSummary,
  IEventTransactionSummary,
  ITransactionShowDetails,
  UserCreateDto,
  DogByClubManagerDto,
  ITransaction,
  EventRegistrationByClubManagerCreateDto,
  IScore,
  NoseworkScoreDto,
  DogShowClassesMoveUpDto,
  IEventMetrics,
  PasswordResetRequestDto,
  PasswordResetDto,
  TransactionRefundDto,
  IEventMessage,
  IShowRunTime,
  FastcatScoreDto,
  IShowRunTimeSlot,
  DogShowClassesDto,
  FetchScoreDto,
} from "@eagerdog/interfaces";
import { Config } from "@eagerdog/config";

import { httpService } from "./http.service";
import { localStorageService } from "./local-storage.service";
import { IFormBuildStatus } from "@eagerdog/interfaces";
import { ShowPrintParamsDto } from "@eagerdog/interfaces";

export namespace apiService {
  // -------------
  const getBaseApiRoute = (): string => {
    return Config.web.REACT_APP_API_URL;
  };

  // -------------
  export const createSubscription = async (
    subscriptionDto: SubscriptionDto
  ): Promise<ISubscription> => {
    //
    const response = await httpService.post(
      `${getBaseApiRoute()}/api/subscriptions`,
      subscriptionDto
    );

    return response.data;
  };

  // -------------
  export const registerOwner = async (
    ownerRegistrationDto: OwnerRegistrationDto
  ): Promise<IUser> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/auth/owner-registration`,
      ownerRegistrationDto
    );

    return processAuthResponse(data);
  };

  // -------------
  export const registerClub = async (
    clubRegistrationDto: ClubRegistrationDto
  ): Promise<IUser> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/auth/club-registration`,
      clubRegistrationDto
    );

    return processAuthResponse(data);
  };

  // -----------------
  const processAuthResponse = (authResponse: IAuthResponse): IUser => {
    const { access_token, user } = authResponse;
    localStorageService.setItem(localStorageService.ACCESS_TOKEN, access_token);
    localStorageService.setItem(localStorageService.USER, JSON.stringify(user));
    return user;
  };

  // -------------
  export const login = async (authDto: AuthDto): Promise<IUser> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/auth/login`,
      authDto
    );

    return processAuthResponse(data);
  };

  // -------------
  export const logout = async (): Promise<void> => {
    //
    localStorageService.removeItem(localStorageService.ACCESS_TOKEN);
    localStorageService.removeItem(localStorageService.USER);
    await httpService.post(`${getBaseApiRoute()}/api/auth/logout`);
  };

  // -------------
  export const verifyEmail = async (
    emailVerificationDto: EmailVerificationDto
  ): Promise<IUser> => {
    //
    const { data: user } = await httpService.post(
      `${getBaseApiRoute()}/api/auth/email-verification`,
      emailVerificationDto
    );

    localStorageService.setItem(localStorageService.USER, JSON.stringify(user));

    return user;
  };

  // -------------
  export const resendEmailVerification = async (): Promise<void> => {
    //
    await httpService.post(
      `${getBaseApiRoute()}/api/auth/resend-email-verification`
    );
  };

  // -------------
  export const whoAmI = async (): Promise<IUser> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/auth/whoami`
    );

    return data;
  };

  // -------------
  export const updateSelf = async (
    userUpdateDto: UserUpdateDto
  ): Promise<IUser> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/auth/update-user`,
      userUpdateDto
    );

    return data;
  };

  // -------------
  export const updateSelfPassword = async (
    updateUserPasswordDto: UpdatePasswordDto
  ): Promise<IUser> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/auth/update-password`,
      updateUserPasswordDto
    );

    return data;
  };

  // -------------
  export const passwordResetRequest = async (
    passwordResetRequestDto: PasswordResetRequestDto
  ): Promise<void> => {
    //
    await httpService.post(
      `${getBaseApiRoute()}/api/auth/password-reset-request`,
      passwordResetRequestDto
    );
  };

  // -------------
  export const passwordReset = async (
    passwordResetDto: PasswordResetDto
  ): Promise<void> => {
    //
    await httpService.post(
      `${getBaseApiRoute()}/api/auth/password-reset`,
      passwordResetDto
    );
  };

  // ----------------
  export const getBreeds = async (query?: QueryDto): Promise<IBreed[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/breeds/actions/search`,
      query || {}
    );

    return data;
  };

  // -------------
  export const createDog = async (dogDto: DogDto): Promise<IDog> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/dogs`,
      dogDto
    );

    return data;
  };

  // -------------
  export const updateDog = async (
    dogId: string,
    dogDto: DogDto
  ): Promise<IDog> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/dogs/${dogId}`,
      dogDto
    );

    return data;
  };

  // -------------
  export const getUserDogs = async (
    userId: string,
    clubId?: string
  ): Promise<IDog[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/dogs/actions/search`,
      {
        query: {
          $and: [
            {
              attribute_name: "owner_id",
              attribute_value: {
                operator: "$eq",
                value: userId,
              },
            },
            {
              attribute_name: "club_id",
              attribute_value: {
                operator: "$eq",
                value: clubId ? clubId : null,
              },
            },
          ],
        },
      }
    );

    return data;
  };

  // ----------------
  export const getClubs = async (query?: QueryDto): Promise<IClub[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/clubs/actions/search`,
      query || {}
    );

    return data;
  };

  // ----------------
  // The current user must be the club manager
  export const getCurrentClub = async (): Promise<IClub> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/clubs/current`
    );

    return data;
  };

  // -------------
  // Events
  // -------------
  // The current user must be the club manager
  export const createEvent = async (eventDto: EventDto): Promise<IEvent> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events`,
      eventDto
    );

    return data;
  };

  // -------------
  export const updateEvent = async (
    eventId: string,
    eventDto: EventDto
  ): Promise<IEvent> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}`,
      eventDto
    );

    return data;
  };

  // -------------
  // public
  export const getEventSummaryForHandle = async (
    handle: string
  ): Promise<IEventSummary> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${handle}`
    );

    return data;
  };

  // -------------
  // public route
  // export const getClubEvents = async (
  //   clubId: string,
  //   queryDto?: QueryDto
  // ): Promise<IEvent[]> => {
  //   //
  //   const { data } = await httpService.post(
  //     `${getBaseApiRoute()}/api/clubs/${clubId}/events/actions/search`,
  //     queryDto || {}
  //   );

  //   return data;
  // };

  // -------------
  // public route
  // export const getClubEvent = async (
  //   clubId: string,
  //   eventId: string
  // ): Promise<IEvent> => {
  //   //
  //   const { data } = await httpService.get(
  //     `${getBaseApiRoute()}/api/clubs/${clubId}/events/${eventId}`
  //   );

  //   return data;
  // };

  // -------------
  // Shows
  // -------------
  export const createShow = async (
    eventId: string,
    showDto: ShowDto
  ): Promise<IShow> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows`,
      showDto
    );

    return data;
  };

  // -------------
  export const updateShow = async (
    eventId: string,
    showId: string,
    showDto: ShowDto
  ): Promise<IShow> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}`,
      showDto
    );

    return data;
  };

  // -------------
  export const deleteShow = async (
    eventId: string,
    showId: string
  ): Promise<void> => {
    //
    await httpService.delete_(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}`
    );
  };

  // -------------
  export const getShowRunTimes = async (
    eventId: string,
    showId: string,
    queryDto?: QueryDto
  ): Promise<IShowRunTime[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/run-times/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -------------
  export const getShowRunTimeSlots = async (
    eventId: string,
    showId: string,
    queryDto?: QueryDto
  ): Promise<IShowRunTimeSlot[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/run-time-slots/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -------------
  // public route
  export const getClubEventShows = async (
    clubId: string,
    eventId: string,
    queryDto?: QueryDto
  ): Promise<IShow[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/clubs/${clubId}/events/${eventId}/shows/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -------------
  // All events (public route)
  export const getEvents = async (
    queryDto?: QueryDto
  ): Promise<IEventSummary[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -------------
  // Owner Events (protected route)
  export const getOwnerEvents = async (
    queryDto?: QueryDto
  ): Promise<IEventSummary[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/actions/search/owner`,
      queryDto || {}
    );

    return data;
  };
  // -------------
  // A clubs events (protected, includes published and draft events)
  export const getClubEvents = async (
    club_id: string,
    queryDto?: QueryDto
  ): Promise<IEventSummary[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/clubs/${club_id}/events/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -------------
  // Get Current Users Clubs
  export const getCurrentUserClubs = async (): Promise<IClub> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/clubs/current`
    );

    return data;
  };

  // ---------------
  // Event registration - calculate the payment summary
  // Currently only dog owners can register dogs
  export const getRegistrationPaymentSummary = async (
    eventId: string,
    registrationDto: EventRegistrationCreateDto
  ): Promise<ITransactionPaymentSummary> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/registration-payment-summary`,
      registrationDto
    );

    const {
      account_number,
      currency,
      amount_multiplier,
      calculated_base_amount,
      calculated_discount_amount,
      calculated_application_fee,
      calculated_processing_fee,
      calculated_processing_tax,
      calculated_total_amount,
      details,
    } = data as ITransactionAmounts & { account_number: string } & {
      details: ITransactionShowDetails[];
    };

    return {
      account_number,
      currency,
      base_amount: calculated_base_amount * amount_multiplier,
      discount: calculated_discount_amount * amount_multiplier,
      fees:
        (calculated_application_fee +
          calculated_processing_fee +
          calculated_processing_tax) *
        amount_multiplier,
      total_amount: calculated_total_amount * amount_multiplier,
      details,
    };
  };

  // ---------------
  // Stripe link to onboard the account
  // The FE needs to provide the return/refresh urls
  export const getAccountOnboardingLink = async (
    clubId: string,
    accountLinkDto: AccountLinkDto
  ): Promise<{ url: string }> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/clubs/${clubId}/account-link`,
      accountLinkDto
    );

    return data;
  };

  // ---------------
  // Stripe link to onboard the account
  // The FE needs to provide the return/refresh urls
  export const getAccountStatus = async (
    clubId: string
  ): Promise<IAccountStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/clubs/${clubId}/account-status`
    );

    return data;
  };

  // ---------------
  // Event registration - calculate the payment summary
  // Currently only dog owners can register dogs.
  // null is returned in case of "manual" registration (not supported currently)
  export const createRegistrationPaymentIntent = async (
    eventId: string,
    registrationDto: EventRegistrationCreateDto
  ): Promise<IEventRegistrationResponse | null> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/registrations`,
      registrationDto
    );

    return data;
  };

  // ---------------
  // Event registration - calculate the payment summary
  // Currently only dog owners can register dogs.
  // null is returned in case of "manual" registration (not supported currently)
  export const createRegistrationByClubManager = async (
    eventId: string,
    registrationDto: EventRegistrationByClubManagerCreateDto
  ): Promise<ITransaction> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/registrations-by-club-manager`,
      registrationDto
    );

    return data;
  };

  // ---------------
  // Event registration - calculate the payment summary
  // Currently only dog owners can register dogs.
  // null is returned in case of "manual" registration (not supported currently)
  export const generateArmNumbersPerShowType = async (
    eventId: string
  ): Promise<void> => {
    //
    await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/show-type-arm-numbers`
    );
  };

  // ----------------
  // Protected route - club managers can view the event registrations
  export const getEventRegistrations = async (
    eventId: string,
    queryDto?: QueryDto
  ): Promise<IEventRegistration[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/registrations/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // ----------------
  // Protected route - club managers can view the event registrations
  export const updateShowRegistration = async (
    eventId: string,
    registrationId: string,
    updateDto: EventRegistrationUpdateDto
  ): Promise<IEventRegistration> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/show-registrations/${registrationId}`,
      updateDto
    );

    return data;
  };

  // ----------------
  /**
   * @deprecated The method should not be used anymore
   */
  // export const dogClassesMoveUp = async (
  //   eventId: string,
  //   showRegistrationId: string,
  //   updateDto: DogShowClassesMoveUpDto
  // ): Promise<IEventRegistration> => {
  //   //
  //   const { data } = await httpService.put(
  //     `${getBaseApiRoute()}/api/events/${eventId}/show-registrations/${showRegistrationId}/classes-moveup`,
  //     updateDto
  //   );

  //   return data;
  // };

  // ----------------
  export const dogClassMoveUp = async (
    eventId: string,
    moveUpClasses: DogShowClassesMoveUpDto
  ): Promise<void> => {
    //
    await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/scores/class-moveup`,
      moveUpClasses
    );
  };

  // ----------------
  export const dogClassUpdates = async (
    eventId: string,
    classUpdates: DogShowClassesDto
  ): Promise<void> => {
    //
    await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/scores/class-updates`,
      classUpdates
    );
  };

  // ---------------
  export const getRegisteredDogsByCurrentUser = async (
    eventId: string,
    queryDto?: QueryDto
  ): Promise<IDog[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/registered-dogs/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // ----------------
  // Protected route - club managers can view registrations (to discuss?)
  export const getEventShowRegistrations = async (
    eventId: string,
    queryDto?: QueryDto
  ): Promise<IShowRegistrationSummary[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/show-registrations/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -----------------
  export const removeDogFromShow = async (
    eventId: string,
    showId: string,
    dogId: string
  ): Promise<void> => {
    //
    await httpService.delete_(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/dogs/${dogId}`
    );
  };

  // ----------------
  export const getEventDiscounts = async (
    eventId: string,
    queryDto?: QueryDto
  ): Promise<IDiscount[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/discounts/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // ----------------
  export const createEventDiscount = async (
    eventId: string,
    discountDto: DiscountDto
  ): Promise<IDiscount> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/discounts`,
      discountDto
    );

    return data;
  };

  // ----------------
  export const updateEventDiscount = async (
    eventId: string,
    discountId: string,
    discountDto: DiscountDto
  ): Promise<IDiscount> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/discounts/${discountId}`,
      discountDto
    );

    return data;
  };

  // ----------------
  export const deleteEventDiscount = async (
    eventId: string,
    discountId: string
  ): Promise<void> => {
    //
    await httpService.delete_(
      `${getBaseApiRoute()}/api/events/${eventId}/discounts/${discountId}`
    );
  };

  // ----------------
  export const getEventTransactions = async (
    eventId: string,
    queryDto?: QueryDto
  ): Promise<IEventTransactionSummary[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/transactions/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // ----------------
  export const refundEventTransaction = async (
    eventId: string,
    transactionId: string,
    refundDto: TransactionRefundDto
  ): Promise<void> => {
    //
    await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/transactions/${transactionId}/actions/refund`,
      refundDto
    );
  };

  // ----------------
  export const getEventMetrics = async (
    eventId: string
  ): Promise<IEventMetrics> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/metrics`
    );

    return data;
  };

  // ----------------
  export const getUserTransactions = async (
    userId: string,
    queryDto?: QueryDto
  ): Promise<IUserTransactionSummary[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/users/${userId}/transactions/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // --------------
  export const requestEntryForms = async (
    eventId: string,
    dogId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/dogs/${dogId}/forms/entry-form`
    );

    return data;
  };

  // --------------
  export const requestEntryFormsForEvent = async (
    eventId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/entry-form`
    );

    return data;
  };

  // --------------
  export const requestEntryFormsForTransaction = async (
    transactionId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/transactions/${transactionId}/forms/entry-form`
    );

    return data;
  };

  // --------------
  export const requestJudgeBooks = async (
    eventId: string,
    showId: string,
    params?: ShowPrintParamsDto
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/forms/judge-books`,
      params
    );

    return data;
  };

  // --------------
  export const requestScoresheets = async (
    eventId: string,
    showId: string,
    params?: ShowPrintParamsDto
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/forms/scoresheets`,
      params
    );

    return data;
  };

  // --------------
  export const requestEventScoresheets = async (
    eventId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/scoresheets`
    );

    return data;
  };

  // --------------
  export const requestEventJudgeScheduleCalculator = async (
    eventId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/judge-schedule-calculator`
    );

    return data;
  };

  // --------------
  export const requestRegistrationListForEvent = async (
    eventId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/registration-list`
    );

    return data;
  };

  // --------------
  export const requestEventTransactions = async (
    eventId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/transaction-list`
    );
    return data;
  };

  // --------------
  export const requestScoreListForShowType = async (
    eventId: string,
    showType: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/score-list/${showType}`
    );

    return data;
  };

  // --------------
  /**
   *
   * @param eventId
   * @param showId In case of AKC fetch use "*" for showId
   * @param params
   * @returns
   */
  export const requestShowCheckinLists = async (
    eventId: string,
    showId: string,
    params?: ShowPrintParamsDto
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/forms/checkin-lists`,
      params
    );

    return data;
  };

  // --------------
  export const requestShowResultLists = async (
    eventId: string,
    showId: string,
    params?: ShowPrintParamsDto
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/forms/results`,
      params
    );

    return data;
  };

  // --------------
  export const requestResultRibbonLabels = async (
    eventId: string,
    showId: string,
    params?: ShowPrintParamsDto & { labels_to_skip?: number }
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/forms/ribbon-labels`,
      params
    );

    return data;
  };

  // --------------
  export const requestResultsEmailForEvent = async (
    eventId: string,
    showType: string
  ): Promise<void> => {
    //
    await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/results-email/${showType}`
    );
  };

  // --------------
  export const requestResultsEmailForShowRegistration = async (
    eventId: string,
    scoreId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/events/${eventId}/scores/${scoreId}/forms/results-email`
    );

    return data;
  };

  // --------------
  export const requestSendMessageEmailForEvent = async (
    eventId: string,
    content: string
  ): Promise<void> => {
    //

    const eventMessage: IEventMessage = {
      content: content,
    };

    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/actions/sendmessage`,
      eventMessage
    );

    return data;
  };

  // --------------
  export const requestTrialReport = async (
    eventId: string,
    showId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/shows/${showId}/forms/trial-report`
    );

    return data;
  };

  // --------------
  export const requestEventCatalog = async (
    eventId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/forms/event-catalog`
    );

    return data;
  };

  // ---------------
  export const getFormStatus = async (
    formId: string
  ): Promise<IFormBuildStatus> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/forms/${formId}`
    );

    return data;
  };

  // ---------------
  export const downloadForm = async (formId: string): Promise<void> => {
    //
    const response = await httpService.get(
      `${getBaseApiRoute()}/api/forms/${formId}/download`,
      { responseType: "blob" }
    );
    //console.log(`Headers: ${JSON.stringify(response.headers, null, 2)}`)
    const fileNameHeader = response.headers["content-disposition"];
    const fileName = fileNameHeader
      ? fileNameHeader.replace(/\w+; ?filename=(.*)/, "$1")
      : "form.pdf";
    let blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    let file = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = file;
    a.download = decodeURI(fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // --------------
  // Returns an URL of the uploaded file
  export const uploadFile = async (file: File): Promise<string> => {
    //
    const formData = new FormData();
    formData.append("file", file);

    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/storage/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return data;
  };

  // ------------------
  // Club Managers can search users
  export const getUsers = async (queryDto?: QueryDto): Promise<IUser[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/users/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -------------
  // Route for club admins to create a user
  export const createUserByClubManager = async (
    userCreateDto: UserCreateDto
  ): Promise<IUser> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/users`,
      userCreateDto
    );

    return data;
  };

  // -------------
  // Route for club admins to update a user
  export const updateUserByClubManager = async (
    userId: string,
    userUpdateDto: UserUpdateDto
  ): Promise<IUser> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/users/${userId}`,
      userUpdateDto
    );

    return data;
  };

  // -------------
  export const createDogByClubManager = async (
    clubId: string,
    dogDto: DogByClubManagerDto
  ): Promise<IDog> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/clubs/${clubId}/dogs`,
      dogDto
    );

    return data;
  };

  // -------------
  export const getEventScores = async (
    eventId: string,
    queryDto?: QueryDto
  ): Promise<IScore[]> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/events/${eventId}/scores/actions/search`,
      queryDto || {}
    );

    return data;
  };

  // -------------
  export const enterNoseworkScore = async (
    eventId: string,
    scoreId: string,
    scoreDto: NoseworkScoreDto
  ): Promise<IScore> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/scores/${scoreId}/nosework`,
      scoreDto
    );

    return data;
  };

  // -------------
  export const enterFastcatScore = async (
    eventId: string,
    scoreId: string,
    scoreDto: FastcatScoreDto
  ): Promise<IScore> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/scores/${scoreId}/fastcat`,
      scoreDto
    );

    return data;
  };

  // -------------
  export const enterFetchScore = async (
    eventId: string,
    scoreId: string,
    scoreDto: FetchScoreDto
  ): Promise<IScore> => {
    //
    const { data } = await httpService.put(
      `${getBaseApiRoute()}/api/events/${eventId}/scores/${scoreId}/fetch`,
      scoreDto
    );

    return data;
  };

  // --------------
  export const createClubPaymentMethod = async (
    clubId: string
  ): Promise<{ setup_intent_id: string; client_secret: string | null }> => {
    //
    const { data } = await httpService.post(
      `${getBaseApiRoute()}/api/clubs/${clubId}/payment-methods`
    );

    return data;
  };

  // --------------
  export const getClubPaymentMethods = async (
    clubId: string
  ): Promise<any[]> => {
    //
    const { data } = await httpService.get(
      `${getBaseApiRoute()}/api/clubs/${clubId}/payment-methods`
    );

    return data;
  };

  // --------------
  export const deleteClubPaymentMethod = async (
    clubId: string,
    paymentMethodId: string
  ): Promise<void> => {
    //
    await httpService.delete_(
      `${getBaseApiRoute()}/api/clubs/${clubId}/payment-methods/${paymentMethodId}`
    );
  };
}
