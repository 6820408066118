import React from "react";
import { Link } from "react-router-dom";

import UserMenu from "../../components/UserMenu/UserMenu";

import "./Header.scss";

import logo from "../../assets/logo.svg";

interface IProps {}

const Header: React.FC<IProps> = (props) => {
  return (
    <div className="Header">
      <div className="logo"><Link to={"/"}><img src={logo} alt="Eager Dog"/></Link></div>
      <div className="user">
        <UserMenu />
      </div>
    </div>
  );
};

export default Header;
