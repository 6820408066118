import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace WeightPull {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
      type: "checkbox", label: Constants.dog_class_element.performance_weight_pull, multiSelect: true, options: [
        { value: Constants.dog_class_element_level.division_a, id: Constants.dog_class_element_level.division_a },
        { value: Constants.dog_class_element_level.division_b, id: Constants.dog_class_element_level.division_b },
        { value: Constants.dog_class_element_level.veteran, id: Constants.dog_class_element_level.veteran }
     ]
    }]
   }];
}