import React from 'react';

import './Chip.scss';

interface IProps {
  children: string,
  onRemove(e: any): void
}

const Chip: React.FC<IProps> = (props) => {

  return(
    <div className="Chip">
      <div className="text">{props.children}</div>
      <div onClick={props.onRemove} className="close"></div>
    </div>
  );
}

export default Chip;