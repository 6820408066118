import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace AKCFastCat {
  export const form:IEventRegisterRow[] = [{
    inputs: [{
      type: "timeslots",
      label: "Timeslot",
      options: []
    }]
  }];
}