import React, { useEffect, useState, useMemo } from "react";
import { Constants } from "@eagerdog/constants";
import { IScore, IFastcatScore } from "@eagerdog/interfaces";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Input from "src/components/Input/Input";

import "./FastCatResults.scss";

interface IProps {
  result: IScore,
  onChange(result: IFastcatScore): void
}

const FastCatResults: React.FC<IProps> = (props) => {
  const [time, setTime] = useState<string>("");
  const [result, setResult] = useState<IOption>({ value: "None", id: "None" });
  const [loaded, setLoaded] = useState<boolean>(false);

  const resultOptions:IOption[] = useMemo(() => [
    { id: Constants.score_result_type.qualified, value: "Qualified" },
    { id: Constants.score_result_type.not_qualified, value: "NQ"}
  ], []);

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      if (props.result && props.result.fastcat) {
        for (let ro in resultOptions) {
          if (resultOptions[ro].id === props.result.fastcat.result_type) {
            setResult(resultOptions[ro]);
            break;
          }
        }

        setTime(props.result?.fastcat?.time.toString() || "");
      }
      setLoaded(true);
    }
  }, [loaded, props.result, resultOptions]);

  useEffect(() => {
    if (hasChanged) {
      props.onChange({
        time: Number(time),
        result_type: result.id
      });

      setHasChanged(false);
    }
  }, [hasChanged, time, props, result.id]);

  return (
    <div className="FastCatResults">
      <Dropdown value={result} label={"Result"} onChange={(e: any, value: IOption) => { setResult(value); setHasChanged(true); }} options={resultOptions} placeholder="" />
      {result.id === Constants.score_result_type.qualified && <Input type="number" step="0.1" maxLength={5} onChange={(e:any) => { setTime(e.target.value); setHasChanged(true); }} label="Time (SS.MM)" placeholder="Enter the result time" defaultValue={time} />}
    </div>
  );
};

export default FastCatResults;