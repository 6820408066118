import React, { useState, useEffect } from "react";
import { ShowRunTimeSlotDto } from "@eagerdog/interfaces";

import Button from "src/components/Button/Button";
import Input from "src/components/Input/Input";
import { ShowRunTimeSlotDtoId } from "src/components/StepForm/ShowForm/ShowForm";

import "./Timeslots.scss";

interface IProps {
  timeslots?: ShowRunTimeSlotDto[],
  onChange(timeslots: ShowRunTimeSlotDtoId[]): void
}

const Timeslots: React.FC<IProps> = (props) => {
  const defaultTimeslot = {
    time_slot: "",
    num_available: 0,
    note: ""
  };

  const [loaded, setLoaded] = useState<boolean>(false);
  const [timeslots, setTimeslots] = useState<ShowRunTimeSlotDtoId[]>([{...defaultTimeslot}]);

  const addAnother = () => {
    let _timeslots = JSON.parse(JSON.stringify(timeslots));
    _timeslots.push({...defaultTimeslot});

    setTimeslots(_timeslots);
  }

  const removeTimeslot = (index: number) => {
    let _timeslots = JSON.parse(JSON.stringify(timeslots));
    _timeslots.splice(index, 1);

    if (_timeslots.length > 0) {
      setTimeslots(_timeslots);
    }
  }

  const setTimeslot = (index: number, t: ShowRunTimeSlotDtoId) => {
    let _timeslots = JSON.parse(JSON.stringify(timeslots));
    _timeslots[index] = t;

    setTimeslots(_timeslots);
  }

  useEffect(() => {
    if (!loaded) {
      const hydrateTimeslots = () => {
        let _timeslots = JSON.parse(JSON.stringify(props.timeslots));
        setTimeslots(_timeslots);
      }

      if (props.timeslots) {
        hydrateTimeslots();
      }

      setLoaded(true);
    }
  }, [loaded, setLoaded, props.timeslots]);

  useEffect(() => {
    props.onChange(timeslots);
  }, [timeslots, props]);

  return (
    <div className="Timeslots">
      {timeslots.map((t: ShowRunTimeSlotDtoId, index: number) => {
        return(
          <div className={"timeslot slot"+index} key={"slot"+index}>
            <div className="timeslotData">
              <div className="nameTotal">
                <Input required={true} type="text" onChange={(e:any) => { setTimeslot(index, {...t, time_slot: e.target.value }); }} label="Timeslot Label" placeholder="ex. 9:00AM, Morning, etc." defaultValue={t.time_slot} />
                <Input required={true} type="number" min="1" onChange={(e:any) => { setTimeslot(index, {...t, num_available: Number(e.target.value) }); }} label="Dog Limit" defaultValue={t.num_available.toString()} />
              </div>
              <div className="note">
                <Input type="text" onChange={(e:any) => { setTimeslot(index, {...t, note: e.target.value }); }} label="Note (optional)" placeholder={"ex. 'Early Birds Only'"} defaultValue={t.note} maxLength={40} />
              </div>
            </div>
            {timeslots.length > 1 && <div className="timeslotActions">
              <div className="btnWrap"><Button onClick={(e) => { e.preventDefault(); removeTimeslot(index); }} className="delete">Delete</Button></div>
            </div>}
          </div>
        );
      })}
      <div className="actions">
        <Button onClick={(e) => { e.preventDefault(); addAnother(); }}>Add Another Timeslot</Button>
      </div>
    </div>
  );
};

export default Timeslots;