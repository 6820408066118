import React, { useState, useEffect } from "react";
import { Constants } from "@eagerdog/constants";

import { ITransactionShowDetails, ITransactionDogDetails, ITransactionElementDetails, ITransactionPaymentSummary } from "@eagerdog/interfaces";
import { IShowDogs } from "../../routes/EventRegister/EventRegister";
import { helperService } from "src/services/helper.service";

import Input from "../Input/Input";

import "./Cart.scss";

interface IProps {
  onApplyDiscount?(discount: string): void,
  paymentSummary: ITransactionPaymentSummary,
  shows: IShowDogs[]
}

interface ICartDetails extends ITransactionShowDetails {
  expanded: boolean
}

const Cart: React.FC<IProps> = (props) => {
  const [discount, setDiscount] = useState<string>("");
  const [cartDetails, setCartDetails] = useState<ICartDetails[]>([]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props.paymentSummary.currency.toUpperCase() || "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const getShowSubTotal = (show: ITransactionShowDetails) => {
    let total:number = 0;

    for (let d in show.dogs) {
      for (let se in show.dogs[d].show_elements) {
        let showElement = show.dogs[d].show_elements[se];

        if (showElement.fee) {
          total += showElement.fee;
        }
      }
    }

    return total;
  }

  const toggleShowItemDetails = (index: number) => {
    let _cartDetails = JSON.parse(JSON.stringify(cartDetails));
    _cartDetails[index].expanded = !_cartDetails[index].expanded;

    setCartDetails(_cartDetails);
  }

  const pDetails = helperService.usePrevious(props.paymentSummary.details);

  useEffect(() => {
    if (pDetails !== props.paymentSummary.details) {
      let details:ITransactionShowDetails[] = JSON.parse(JSON.stringify(props.paymentSummary.details));
      let cart:ICartDetails[] = JSON.parse(JSON.stringify(cartDetails));

      if (details.length === 0) {
        cart = [];
      } else {
        for (let d in details) {
          if (cart[d]) {
            cart[d] = { ...details[d], expanded: cart[d].expanded };
          } else {
            cart.push({ ...details[d], expanded: false });
          }
        }
      }

      setCartDetails(cart);
    }
  }, [pDetails, props.paymentSummary.details, setCartDetails, cartDetails]);

  const getEmptyText = (showType: string) => {
    let text = "No Shows Selected";

    switch(showType) {
      case Constants.dog_class_element.conformation:
        text = "No Classes Selected";
      break;
      case Constants.dog_class_element.performance_obedience:
        text = "No Levels Selected";
      break;
      case Constants.dog_class_element.performance_rally_obedience:
        text = "No Levels Selected";
      break;
      default:
        text = "No Elements Selected";
      break;
    }

    return text;
  }

  const getShowDetailsName = (se: ITransactionElementDetails, showType: string) => {
    if (helperService.timeSlotShows.includes(showType)) {
      return se.level;
    }

    if (se.show_element === se.level) {
      return "Non-Licensed Class, " + se.level;
    }

    return se.show_element +", "+ se.level +" "+ se.section;
  }

  return (
    <div className="Cart">
      <div className="cartInner">
        <div className="title"><span>Your Cart</span></div>
        <div className="items">
          {cartDetails.map((show: ICartDetails, showIndex: number) => {
            return(
              <div key={showIndex} className="showItem">
                <div className="showTitle">
                  <div className="showName">
                    <div className="showNameInner">{show.show_type} {show.show_name}</div>
                    <span className="showTotal">{show.dogs.length > 0 ? formatter.format(getShowSubTotal(show)) + " ("+ show.dogs.length +" "+ (show.dogs.length > 1 ? "dogs" : "dog") +")" : getEmptyText(show.show_type)}</span>
                  </div>
                  {<div className="showActions"><div onClick={(e) => { toggleShowItemDetails(showIndex); }} className={"icon " + (show.expanded ? "caretDown" : "caretUp")}></div></div>}
                </div>
                {show.expanded && <div className="showDetails no-select">
                  {show.dogs.map((dog: ITransactionDogDetails, dIndex:number) => {
                    return(<div key={dIndex} className="dogList">
                      <div className="dogName">{dog.call_name}</div>
                      {dog.show_elements.length === 0 && <span className="empty">{getEmptyText(show.show_type)}</span>}
                      {dog.show_elements.length > 0 && dog.show_elements.map((se: ITransactionElementDetails, elementIndex:number) => {
                        return(<div key={"dogClass" + elementIndex} className="dogClass">
                          <span>{getShowDetailsName(se, show.show_type)}</span><span>{formatter.format(se.fee)}</span></div>);
                      })}
                    </div>);
                  })}
                </div>}
              </div>
            );
          })}
          {props.paymentSummary.details.length === 0 && <span className="empty">You haven't selected a class/element/level for your dogs shows/trials yet.</span>}
        </div>
        <>{props.onApplyDiscount && <form className="discountCode">
          <Input required label="Discount Code" id="discountCode" type="text" onChange={(e) => { e.stopPropagation(); let _code = e.target.value.toUpperCase().replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/g, ""); setDiscount(_code); }} defaultValue={discount} />
          <button type="submit" onClick={(e: any) => { e.preventDefault(); if (props.onApplyDiscount) { props.onApplyDiscount(discount.toUpperCase()); } }}>Apply</button>
        </form>}</>
        <div className="total">
          <div className="totalInner">
            <div className="sub"><span>Subtotal</span><span>{formatter.format(props.paymentSummary.base_amount || 0)}</span></div>
            <div className="sub"><span>Fees (10% + HST)</span><span>{formatter.format(props.paymentSummary.fees || 0)}</span></div>
            {props.paymentSummary?.discount > 0 && <div className="sub"><span>Discount</span><span>-{formatter.format(props.paymentSummary?.discount)}</span></div>}
            <div className="actual"><span>Total</span><span>{formatter.format(props?.paymentSummary?.total_amount) || 0}</span></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;