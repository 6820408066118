import React from 'react';

import './Checkbox.scss';

interface IProps {
  id: string,
  label: string,
  value: boolean,
  onChange(e: any): void,
  disabled?: boolean
}

export interface ICheck {
  label: string,
  checked: boolean
}

const Checkbox: React.FC<IProps> = (props) => {
  return(
    <div className={props.disabled ? "Checkbox disabled" : "Checkbox"}>
      <label htmlFor={props.id} className="check">
        <input disabled={props.disabled} onChange={(e) => { props.onChange(e); }} id={props.id} type="checkbox" checked={props.value ? true : false} />
        <div className="checkSquare"></div>
        <div className="label">{props.label}</div>
      </label>
    </div>
  );
}

export default Checkbox;