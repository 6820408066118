import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace Conformation {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
      type: "dropdown", flex: 2, label: Constants.dog_class_element.conformation, options: [
        { value: "None", id: "None" },
        { value: Constants.dog_class_element_level.puppy, id: Constants.dog_class_element_level.puppy },
        { value: Constants.dog_class_element_level.junior, id: Constants.dog_class_element_level.junior },
        { value: Constants.dog_class_element_level.adult, id: Constants.dog_class_element_level.adult },
        { value: Constants.dog_class_element_level.open, id: Constants.dog_class_element_level.open },
        { value: Constants.dog_class_element_level.breader_handler, id: Constants.dog_class_element_level.breader_handler },
        { value: Constants.dog_class_element_level.champion, id: Constants.dog_class_element_level.champion },
        { value: Constants.dog_class_element_level.grand_champion, id: Constants.dog_class_element_level.grand_champion }
      ]
    }, {
      type: "dropdown", flex: 2, label: Constants.dog_class_element.conformation_altered, options: [
        { value: "None", id: "None" },
        { value: Constants.dog_class_element_level.puppy, id: Constants.dog_class_element_level.puppy },
        { value: Constants.dog_class_element_level.junior, id: Constants.dog_class_element_level.junior },
        { value: Constants.dog_class_element_level.adult, id: Constants.dog_class_element_level.adult },
        { value: Constants.dog_class_element_level.open, id: Constants.dog_class_element_level.open },
        { value: Constants.dog_class_element_level.breader_handler, id: Constants.dog_class_element_level.breader_handler },
        { value: Constants.dog_class_element_level.champion, id: Constants.dog_class_element_level.champion },
        { value: Constants.dog_class_element_level.grand_champion, id: Constants.dog_class_element_level.grand_champion }
      ]
    }]
   }, {
     allowDuplicates: false,
     inputs: [{
       type: "checkbox", label: Constants.dog_class_element.conformation_non_licensed, multiSelect: true, options: [
         { value: Constants.dog_class_element_level.novice_puppy, id: Constants.dog_class_element_level.novice_puppy },
         { value: Constants.dog_class_element_level.veteran, id: Constants.dog_class_element_level.veteran },
         { value: Constants.dog_class_element_level.brood_bitch_stud_dog, id: Constants.dog_class_element_level.brood_bitch_stud_dog },
         { value: Constants.dog_class_element_level.brace, id: Constants.dog_class_element_level.brace },
         { value: Constants.dog_class_element_level.total_dog, id: Constants.dog_class_element_level.total_dog },
         //{ value: Constants.dog_class_element_level.other, id: Constants.dog_class_element_level.other },
       ]
     }]
   }];
}