import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import Login from "./routes/Login/Login";
import Logout from "./routes/Logout/Logout";
import VerifyEmail from "./routes/VerifyEmail/VerifyEmail";
import ResetPassword from './routes/ResetPassword/ResetPassword';
import ChooseNewPassword from './routes/ChooseNewPassword/ChooseNewPassword';

import Account from "./routes/Account/Account";

import Register from "./routes/Register/Register";
import DogOwner from "./routes/Register/DogOwner/DogOwner";
import KennelClub from "./routes/Register/KennelClub/KennelClub";

import Events from "./routes/Events/Events";
import Event from "./routes/Event/Event";
import EventRegister from "./routes/EventRegister/EventRegister";
import EventManage from "./routes/EventManage/EventManage";
import PrivacyPolicy from "./routes/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./routes/TermsOfService/TermsOfService";

import NotFound from "./routes/NotFound/NotFound";
import Header from "./components/Header/Header";

import './App.css';

function App() {
  const location = useLocation();
  const [locationStyle, setLocationStyle] = useState<string>();

  const hiddenHeaderLocations:string[] = [
    "/login",
    "/register",
    "/register/kennel-club",
    "/register/dog-owner"
  ];

  useEffect(() => {
    document.body.style.overflow = 'unset';
    let _locationStyle = location.pathname.split("/");

    if (_locationStyle.length > 1 && _locationStyle[1].length > 0) {
      setLocationStyle(_locationStyle[1].toLowerCase() + "Page");
    } else {
      setLocationStyle("homePage");
    }

  }, [location.pathname]);

  return (
    <div className={hiddenHeaderLocations.includes(location.pathname) ? "Eagerdog noHeader " + locationStyle : "Eagerdog " + locationStyle}>
      <Header />
      <Routes>
        <Route path="/" element={<Events />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register/dog-owner" element={<DogOwner />} />
        <Route path="/register/kennel-club" element={<KennelClub />} />
        <Route path="/send-code" element={<ResetPassword />} />
        <Route path="/reset-password" element={<ChooseNewPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/e/:eid" element={<Event />} />
        <Route path="/e/:eid/register" element={<EventRegister />} />
        <Route path="/e/:eid/manage" element={<EventManage />} />
        <Route path="/account" element={<Account />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </div>
  );
}

export default App;