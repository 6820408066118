import React, { useEffect, useState } from "react";

import Input from "src/components/Input/Input";

import "./StopWatch.scss";

interface IProps {
	onChange(time: string): void,
    time: string
}

const StopWatch: React.FC<IProps> = (props) => {
    const [loaded, setLoaded] = useState<boolean>(false);

    const [seaMin, setSeaMin] = useState<string>("");
    const [seaSec, setSeaSec] = useState<string>("");
    const [seaTho, setSeaTho] = useState<string>("");

    useEffect(() => {
        if (!loaded) {
            setSeaMin(props.time.split(":")[0]);
            setSeaSec(props.time.split(":")[1]);
            setSeaTho(props.time.split(":")[2]);
            setLoaded(true);
        }
    }, [loaded, props.time]);

    const goToNext = (e: any) => {
        let t:string = "seaMin";

        let noNoKeys:string[] = [
          "Backspace",
          "Tab",
          "ShiftLeft",
          "ShiftRight",
          "ControlLeft",
          "ControlRight",
          "KeyA"
        ];

        switch(e.target.id) {
          case "seaMin":
            t = "seaSec";
          break;
          case "seaSec":
            t = "seaTho";
          break;
        }

        if (!noNoKeys.includes(e.code) && !isNaN(e.target.value) && e.target.value.length >= 2) {
          document.getElementById(t)?.focus();
        }
    }

    return (
    <div className="StopWatch reason qualified">
      <Input
        label="Search Time (mm)"
        id="seaMin"
        type="text"
        onChange={(e) => {
          e.stopPropagation();
          setSeaMin(e.target.value);
          props.onChange(e.target.value+":"+seaSec+":"+seaTho);
        }}
        placeholder={"mm"}
        defaultValue={seaMin}
        maxLength={2}
        onKeyUp={(e:any) => { goToNext(e); }}
      />
      <Input
        label="Search Time (ss)"
        id="seaSec"
        type="text"
        onChange={(e) => {
          e.stopPropagation();
          setSeaSec(e.target.value);
          props.onChange(seaMin+":"+e.target.value+":"+seaTho);
        }}
        placeholder={"ss"}
        defaultValue={seaSec}
        maxLength={2}
        onKeyUp={(e:any) => { goToNext(e); }}
      />
      <Input
        label="Search Time (tt)"
        id="seaTho"
        type="text"
        onChange={(e) => {
          e.stopPropagation();
          setSeaTho(e.target.value);
          props.onChange(seaMin+":"+seaSec+":"+e.target.value);
        }}
        placeholder={"tt"}
        defaultValue={seaTho}
        maxLength={2}
      />
    </div>
    );
};

export default StopWatch;