import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { helperService, IDogPortrait } from "../../../services/helper.service";
import { Helmet } from "react-helmet";

import { OwnerRegistrationDto } from "@eagerdog/interfaces";

import "src/styles/Signup.scss";

import logo from "src/assets/logo.svg";
import emailIcon from "src/assets/icons/email.svg";
import keyIcon from "src/assets/icons/key.svg";

import Checkbox from "src/components/Checkbox/Checkbox";
import Input from "src/components/Input/Input";
import Phone from "src/components/Phone/Phone";
import Button from "src/components/Button/Button";
import { toast } from "src/components/Toast/ToastManager";

import { apiService } from "src/services";

interface IProps {}

const DogOwner: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [first, setFirst] = useState<string>("");
  const [last, setLast] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [isJunior, setIsJunior] = useState<boolean>(false);
  const [parentName, setParentName] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [repeat, setRepeat] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const stepAmt:number = 2;

  let _url = window.location.href.split("/e/")[1];
  let redirect = _url ? `/e/${_url}` : "/";

  useEffect(() => {
    if (helperService.isLoggedIn()) {
      navigate("/");
    }
  });

  const getStepText = () => {
    return (<span>{step + 1} / {stepAmt}</span>);
  }

  const passwordMatch = () => {
    if (password !== repeat) {
      toast.show({
        title: "Error",
        content: "The passwords you entered do not match",
        duration: 10000,
        type: "fail"
      });

      return false;
    }

    return true;
  }

  const register = () => {
      let payload:OwnerRegistrationDto = {
        email: email,
        password: password,
        first_name: first,
        last_name: last,
        phone: phone
      };

      if (isJunior) {
        payload.is_junior = isJunior;
        payload.junior_numbers = [];
        payload.parent_name = parentName;
      }

      setIsLoading(true);

      apiService.registerOwner(payload).then((response: any) => {
        if (response._id) {
          if (redirect) {
            navigate('/verify-email?t=owner&r=' + redirect);
          } else {
            navigate('/verify-email?t=owner');
          }
        }
      }).catch((error: any) => {
          toast.show({
            title: "Error",
            content: error.response.data.message ? error.response.data.message : "Something went wrong, please try again later",
            duration: 10000,
            type: "fail"
          });
      }).finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="DogOwner Signup">
      <Helmet>
        <title>Register - Dog Owner</title>
        <meta name="description" content="Register as a dog owner on Eager Dog." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="images">
        <div className="logo"><Link to="/"><img src={logo} alt="Eager Dog" /></Link></div>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }} className="dogCards">
          <div className="cardRow">
            {helperService.topDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
          <div className="cardRow">
            {helperService.bottomDogs.map((dog:IDogPortrait, i:number) => {
              return(
                <div key={i} className="dogCard">
                  <div className="dogPortrait">
                    <img src={dog.image} alt={"Dog Portrait of " + dog.name} />
                  </div>
                  <span>{dog.name}</span>
                </div>
              );
            })}
          </div>
        </motion.div>
        <motion.div initial={{ opacity: 0, translateY: "-300px" }} whileInView={{ opacity: 1, translateY: "-50px" }} transition={{ delay: 0.5 }} className="dogBanner">
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 1 }} className="bannerInner">
            <div className="small">The Best Place</div>
            <div className="large">for your <span>Dog</span></div>
            <div className="dogWrap">
              <div className="portrait"><img src={helperService.allDogs[1].image} alt={"Dog Portrait of  " + helperService.allDogs[1].name} /></div>
            </div>
            <div className="dogName">{helperService.allDogs[1].name}</div>
          </motion.div>
        </motion.div>
      </div>
      <div className="content">
        <div className="inner">
          <div className="logo"><img src={logo} alt="Eager Dog" /></div>
          <h1>Register</h1>
          <div className="stepWrap">
            <div className="steps" style={{ width: (100 * stepAmt) + "%", transform: "translateX(" + -((step * 100) / stepAmt) + "%)" }}>
              <div className="step" style={{ width: (100 / stepAmt) + "%" }}>
                <div className="stepInner">
                  <div className="form">
                    <form onSubmit={(e) => { e.preventDefault(); setStep(step + 1); }}>
                      <div className="stepTitle"><span>Your Information</span>{ getStepText() }</div>
                      <Input required onChange={(e) => { setEmail(e.target.value); }} type="email" icon={emailIcon} label="Email" placeholder="Enter your email" />
                      <div className="double">
                        <Input required onChange={(e) => { setFirst(e.target.value); }} type="first" label="First Name" placeholder="Enter your first name" />
                        <Input required onChange={(e) => { setLast(e.target.value); }} type="last" label="Last Name" placeholder="Enter your last name" />
                      </div>
                      <Phone required label="Phone Number" value={phone} onChange={setPhone} />
                      <div className="juniorWrap">
                        <Checkbox onChange={(e) => { setIsJunior(!isJunior); }} value={isJunior} id="isJunior" label="This is a junior account" />
                        {isJunior && <Input required onChange={(e) => { setParentName(e.target.value); }} type="parentName" label="Parent Name" placeholder="What's your parents name?" />}
                      </div>
                      <div className="actions">
                        <button type="submit">Next</button>
                      </div>
                      <div className="register">Already have an account? <Link to="/login">Log in</Link></div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="step" style={{ width: (100 / stepAmt) + "%" }}>
                <div className="stepInner">
                  <div className="form">
                    <form onSubmit={(e) => { e.preventDefault(); if (passwordMatch()) { register(); } }}>
                      <div className="stepTitle"><span>Your Information</span>{ getStepText() }</div>
                      <Input onChange={(e) => { setPassword(e.target.value); }} required type="password" icon={keyIcon} label="Password" placeholder="Enter your password" />
                      <Input onChange={(e) => { setRepeat(e.target.value); }} required type="password" icon={keyIcon} label="Repeated Password" placeholder="Enter your password again" />
                      <div className="policy">
                        <p>By clicking Finish, you agree to our <Link to="/terms-of-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</p>
                      </div>
                      <div className="actions two">
                        <button type="button" onClick={() => { setStep(step - 1); }}>Previous</button>
                        <Button isLoading={isLoading} type="submit">Finish</Button>
                      </div>
                      <div className="register">Already have an account? <Link to="/login">Log in</Link></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DogOwner;
