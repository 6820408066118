import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace AKCScentwork {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.scentwork_container,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.scentwork_novice_a,
              id: Constants.dog_class_element_level.scentwork_novice_a,
            },
            {
              value: Constants.dog_class_element_level.scentwork_novice_b,
              id: Constants.dog_class_element_level.scentwork_novice_b,
            },
            {
              value: Constants.dog_class_element_level.scentwork_advanced,
              id: Constants.dog_class_element_level.scentwork_advanced,
            },
            {
              value: Constants.dog_class_element_level.scentwork_excellent,
              id: Constants.dog_class_element_level.scentwork_excellent,
            },
            {
              value: Constants.dog_class_element_level.scentwork_master,
              id: Constants.dog_class_element_level.scentwork_master,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.scentwork_interior,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.scentwork_novice_a,
              id: Constants.dog_class_element_level.scentwork_novice_a,
            },
            {
              value: Constants.dog_class_element_level.scentwork_novice_b,
              id: Constants.dog_class_element_level.scentwork_novice_b,
            },
            {
              value: Constants.dog_class_element_level.scentwork_advanced,
              id: Constants.dog_class_element_level.scentwork_advanced,
            },
            {
              value: Constants.dog_class_element_level.scentwork_excellent,
              id: Constants.dog_class_element_level.scentwork_excellent,
            },
            {
              value: Constants.dog_class_element_level.scentwork_master,
              id: Constants.dog_class_element_level.scentwork_master,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.scentwork_exterior,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.scentwork_novice_a,
              id: Constants.dog_class_element_level.scentwork_novice_a,
            },
            {
              value: Constants.dog_class_element_level.scentwork_novice_b,
              id: Constants.dog_class_element_level.scentwork_novice_b,
            },
            {
              value: Constants.dog_class_element_level.scentwork_advanced,
              id: Constants.dog_class_element_level.scentwork_advanced,
            },
            {
              value: Constants.dog_class_element_level.scentwork_excellent,
              id: Constants.dog_class_element_level.scentwork_excellent,
            },
            {
              value: Constants.dog_class_element_level.scentwork_master,
              id: Constants.dog_class_element_level.scentwork_master,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.scentwork_buried,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.scentwork_novice_a,
              id: Constants.dog_class_element_level.scentwork_novice_a,
            },
            {
              value: Constants.dog_class_element_level.scentwork_novice_b,
              id: Constants.dog_class_element_level.scentwork_novice_b,
            },
            {
              value: Constants.dog_class_element_level.scentwork_advanced,
              id: Constants.dog_class_element_level.scentwork_advanced,
            },
            {
              value: Constants.dog_class_element_level.scentwork_excellent,
              id: Constants.dog_class_element_level.scentwork_excellent,
            },
            {
              value: Constants.dog_class_element_level.scentwork_master,
              id: Constants.dog_class_element_level.scentwork_master,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.scentwork_handler_disc,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.scentwork_novice_a,
              id: Constants.dog_class_element_level.scentwork_novice_a,
            },
            {
              value: Constants.dog_class_element_level.scentwork_novice_b,
              id: Constants.dog_class_element_level.scentwork_novice_b,
            },
            {
              value: Constants.dog_class_element_level.scentwork_advanced,
              id: Constants.dog_class_element_level.scentwork_advanced,
            },
            {
              value: Constants.dog_class_element_level.scentwork_excellent,
              id: Constants.dog_class_element_level.scentwork_excellent,
            },
            {
              value: Constants.dog_class_element_level.scentwork_master,
              id: Constants.dog_class_element_level.scentwork_master,
            },
          ],
        },
      ],
    },
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.scentwork_detective,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.scentwork_detective,
              id: Constants.dog_class_element_level.scentwork_detective,
            },
          ],
        },
      ],
    },
  ];
}
