import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import lostDog from "../../assets/lostDog.png";

import "./NotFound.scss";

interface IProps {}

const NotFound: React.FC<IProps> = (props) => {
  return (
    <div className="NotFound">
      <Helmet>
        <title>Page Not Found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="notFoundInner">
          <div className="content">
            <img src={lostDog} alt="A confused dog!" />
            <h1>404</h1>
            <p>We're sorry</p>
            <p>It looks like you've hit a dead end.</p>
            <div className="actions">
                <Link to="/">Back to Events</Link>
            </div>
          </div>
      </div>
    </div>
  );
};

export default NotFound;
