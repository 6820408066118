import React from 'react';

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./Phone.scss";

interface IProps {
  onChange: any,
  value: any,
  label?: string,
  placeholder?: string
  maxDate?: Date,
  required?: boolean
}

const Phone: React.FC<IProps> = (props) => {
  return(
    <div className="Phone">
      <div className="label">{props.label ? props.label : "Phone Number"}</div>
      <PhoneInput required={props.required ? props.required : false} defaultCountry="US" placeholder={props.placeholder ? props.placeholder : ""} value={props.value} onChange={props.onChange} />
    </div>
  );
}

export default Phone;