import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace Nosework {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: true,
      inputs: [{
        type: "dropdown", flex: 2, label: Constants.dog_class_element.nosework_container, options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
          { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
          { value: Constants.dog_class_element_level.superior, id: Constants.dog_class_element_level.superior },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
          { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite }
        ]
      },
      {
        type: "dropdown", defaultValue: "None", label: "Section", options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_section.nosework_a, id: Constants.dog_class_section.nosework_a },
          { value: Constants.dog_class_section.nosework_b, id: Constants.dog_class_section.nosework_b },
        ]
      }]
    }, {
      allowDuplicates: true,
      inputs: [{
        type: "dropdown", flex: 2, label: Constants.dog_class_element.nosework_exterior, options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
          { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
          { value: Constants.dog_class_element_level.superior, id: Constants.dog_class_element_level.superior },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
          { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite }
        ]
      },
      {
        type: "dropdown", defaultValue: "None", label: "Section", options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_section.nosework_a, id: Constants.dog_class_section.nosework_a },
          { value: Constants.dog_class_section.nosework_b, id: Constants.dog_class_section.nosework_b },
        ]
      }]
    }, {
      allowDuplicates: true,
      inputs: [{
        type: "dropdown", flex: 2, label: Constants.dog_class_element.nosework_interior, options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
          { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
          { value: Constants.dog_class_element_level.superior, id: Constants.dog_class_element_level.superior },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
          { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite }
        ]
      },
      {
        type: "dropdown", defaultValue: "None", label: "Section", options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_section.nosework_a, id: Constants.dog_class_section.nosework_a },
          { value: Constants.dog_class_section.nosework_b, id: Constants.dog_class_section.nosework_b },
        ]
      }]
    }, {
      allowDuplicates: true,
      inputs: [{
        type: "dropdown", flex: 2, label: Constants.dog_class_element.nosework_vehicle, options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
          { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
          { value: Constants.dog_class_element_level.superior, id: Constants.dog_class_element_level.superior },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
          { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite }
        ]
      },
      {
        type: "dropdown", defaultValue: "None", label: "Section", options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_section.nosework_a, id: Constants.dog_class_section.nosework_a },
          { value: Constants.dog_class_section.nosework_b, id: Constants.dog_class_section.nosework_b },
        ]
      }]
    }, {
      allowDuplicates: true,
      inputs: [{
        type: "dropdown", flex: 2, dropUp: true, label: Constants.dog_class_element.nosework_handler_descrimination, options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
          { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
          { value: Constants.dog_class_element_level.excellent, id: Constants.dog_class_element_level.excellent },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master }
        ]
      },
      {
        type: "dropdown", defaultValue: "None", label: "Section", options: [
          { value: "None", id: "None" },
          { value: Constants.dog_class_section.nosework_a, id: Constants.dog_class_section.nosework_a },
          { value: Constants.dog_class_section.nosework_b, id: Constants.dog_class_section.nosework_b },
        ]
      }]
   }];
}